import React, {useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EncodingSlidePanel from "../../../../../components/partials/panel/encding-slide-panel";
import LoaderWrapper from "../../../../../components/common/loader/loader-wrapper";
import { getElement, putElement } from "../../../../../api/config";
import { CHECK_ENCODE, COMPANY, PRODUCT_ENCCODE, PRODUCT_ENCODE_ATTRIBUTE, PRODUCT_ENCODE_ATTRIBUTES } from "../../../../../api/endpoints";
import { getPathParam, getWebPathParam, makeid } from "../../../../../utils/converter";
import { ChevronRightIcon } from "@heroicons/react/outline";
import SimpleButtonSubmit from "../../../../../components/common/form/simple-button-submit";
import { putProduct } from "../../../../../api/catalog";
import toast from "react-hot-toast";
import { Warning } from "../../../../../components/common/alert/banner";
import ProductCell from "../../../../../components/common/table/product-cell";
import { CATALOG, PRODUCT, PRODUCT_DETAIL } from "../../../../endpoints";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function formatNumberWithLeadingZeros(number, start_from) {
    let startString = start_from.toString();
    let zerosToAdd = number - startString.length;
    let result = zerosToAdd > 0 ? '0'.repeat(zerosToAdd) + startString : startString;
    return result;
}


const GenerateEncoding = ({ isOpenPanel, setOpenPanel, encodingData, productDetail = null, onUpdateProductDetail, onInsertCode }) => {

    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();
    const lastGroup = useRef(null);
    const scrollTo = () => {
        lastGroup.current.scrollIntoView({ behavior: "smooth", left: lastGroup.current.scrollWidth })
    }

    // Encode Manage
    const [isLoadingConfiguration, setLoadingConfiguration] = useState(true);
    const [configurationColumn, setConfigurationColumn] = useState([{ type: 'primary', position: 1, childId: '', configuration: [], isLoading: false }]);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (isOpenPanel && loggedCompany) {
                setLoadingConfiguration(true);
                setConfigurationColumn([{ type: 'primary', position: 1, childId: '', configuration: [] }]);
                try {
                    const res = await getElement(getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTES]), signal);
                    let filtered = [...configurationColumn];
                    let foundIndex = filtered.findIndex((x) => x.type === 'primary');
                    filtered[foundIndex]['configuration'] = res.product_encode_attributes;
                    setConfigurationColumn(filtered)
                } catch (e) {
                    setLoadingConfiguration(false);
                } finally {
                    setLoadingConfiguration(false);
                }
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, isOpenPanel]);


    const generateProductReference = () => {
        let GeneretedCode = encodingData.code;
        if (configurationColumn && configurationColumn.length > 1) {
            // eslint-disable-next-line
            configurationColumn.map((configuration) => {

                if (configuration.childId && configuration.childId !== '') {
                    const confResult = configurationColumn.find(r =>
                        r.configuration.some(n => n.id === configuration.childId)
                    )?.configuration.find(n => n.id === configuration.childId);

                    if (confResult) {
                        if (confResult.type === 'incrementer') {
                            GeneretedCode = GeneretedCode + formatNumberWithLeadingZeros(confResult.code, confResult.start_from)
                        } else {
                            GeneretedCode = GeneretedCode + confResult.code
                        }
                    }

                }
            })
        }
        //
        return GeneretedCode;
    }


    const fetchSubConfiguration = async (position, encode) => {
        if (loggedCompany) {
            try {


                let dynamicId = makeid(10);
                let filtered = [...configurationColumn];
                filtered = filtered.filter(r => position >= r.position);
                let nuovaPosizione = position + 1;
                filtered.push({
                    type: 'child',
                    dynamicId: dynamicId,
                    position: nuovaPosizione,
                    childId: '',
                    cardType: '',
                    titleAttribute: '',
                    titleSubAttribute: '',
                    configuration: '',
                    isLoading: true
                });
                setConfigurationColumn(filtered);

                let isActiveErorr = false;
                if (generateProductReference().length > encodingData.number_limit){
                    isActiveErorr = true
                }


                setTimeout(async function () {
                    const res = await getElement(getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTE, encode.id]));
                    let filtered2 = [...filtered];
                    let foundIndex = filtered2.findIndex((x) => x.dynamicId === dynamicId);

                    if (isActiveErorr){
                        filtered[foundIndex]['type'] = 'generetorLimit';
                        filtered[foundIndex]['childId'] = res.id;
                        filtered[foundIndex]['cardType'] = 'generetorLimit';
                        filtered[foundIndex]['titleAttribute'] = '';
                        filtered[foundIndex]['titleSubAttribute'] = '';
                        filtered[foundIndex]['configuration'] = [];

                    }else{
                        if (res.product_encode_attributes.length > 0) {
                            filtered[foundIndex]['childId'] = res.id;
                            filtered[foundIndex]['cardType'] = res.type;
                            filtered[foundIndex]['titleAttribute'] = res?.children_title ?? t("app.catalog.sub_attributes");
                            filtered[foundIndex]['titleSubAttribute'] = res?.company_sub_attribute?.name ?? '';
                            filtered[foundIndex]['configuration'] = res.product_encode_attributes;
                        } else {
                            filtered[foundIndex]['type'] = 'generetor';
                            filtered[foundIndex]['childId'] = res.id;
                            filtered[foundIndex]['cardType'] = 'generetor';
                            filtered[foundIndex]['titleAttribute'] = '';
                            filtered[foundIndex]['titleSubAttribute'] = '';
                            filtered[foundIndex]['configuration'] = [];
                        }
                    }



                    filtered[foundIndex]['isLoading'] = false;

                    setConfigurationColumn(filtered2);
                    scrollTo();
                }, 50);

                } catch (e) {
                //console.log(e);
            }
        }
    }





    return (
        <EncodingSlidePanel
            isOpen={isOpenPanel}
            setIsOpen={setOpenPanel}
            setClean={() => {
                setLoadingConfiguration(true);
                setConfigurationColumn([{ type: 'primary', position: 1, childId: '', configuration: [] }]);
            }}
            encodeLimit={encodingData.number_limit}
            codeHeader={generateProductReference()}
            title={encodingData?.label ?? ''}

        >
            <LoaderWrapper isLoading={isLoadingConfiguration}>

                <div class={"grow relative mt-3 h-[100vh]"}>
                    <div className="transition ease-in-out delay-150 overflow-x-auto overflow-y-auto h-[90%] absolute select-none whitespace-nowrap mb-2 pt-0.5 pb-2 -top-0.5 bottom-0 inset-x-0 flex flex-row px-1.5 py-0">


                        {
                            configurationColumn.map((configuration, index) => (
                                <>
                                    {configuration.type === 'primary' && (
                                        <PrimaryCombinationColumn
                                            key={index}
                                            encodingData={encodingData}
                                            position={configuration.position}
                                            isActiveCard={configurationColumn?.find(r => r.position === (configuration.position + 1))?.childId ?? false}
                                            primaryConfiguration={configuration.configuration}
                                            onOpenChildren={async (position, r) => {
                                                await fetchSubConfiguration(position, r);
                                            }}
                                        />
                                    )}

                                    {configuration.type === 'child' && (
                                        <CombinationColumn
                                            key={index}
                                            title={configuration.cardType === 'incrementer' ? t("app.catalog.incremental") : configuration.titleAttribute}
                                            encodingData={encodingData}
                                            isLoading={configuration.isLoading}
                                            childId={configuration.childId}
                                            isActiveCard={configurationColumn?.find(r => r.position === (configuration.position + 1))?.childId ?? false}
                                            position={configuration.position}
                                            configurations={configuration.configuration}
                                            configurationColumn={configurationColumn}
                                            onOpenChildren={async (position, r) => {
                                                await fetchSubConfiguration(position, r);
                                            }}
                                        />
                                    )}
                                    {configuration.type === 'generetor' && (
                                        <GenereteCode
                                            key={index}
                                            encodingData={encodingData}
                                            productDetail={productDetail}
                                            title={t('app.catalog.generete_code')}
                                            configurationColumn={configurationColumn}
                                            onUpdateProductDetail={onUpdateProductDetail}
                                            onInsertCode={onInsertCode}
                                        />
                                    )}

                                    {configuration.type === 'generetorLimit' && (
                                        <ErrorCombinationColumn
                                            key={index}
                                            code={generateProductReference()}
                                            isLoading={configuration.isLoading}
                                            encodingData={encodingData}
                                        />
                                    )}


                                </>

                            ))
                        }


                        <div ref={lastGroup} />


                    </div>

                </div>

            </LoaderWrapper>


        </EncodingSlidePanel>
    );
};



// to finish
const PrimaryCombinationColumn = ({ primaryConfiguration, onOpenChildren, position, isActiveCard }) => {

    const { t } = useTranslation();

    return (
        <div className={`self-start shrink-0 block  h-full  whitespace-nowrap px-1.5 py-0`} >
            <div className="bg-solitude-400  rounded-lg shadow-md  flex justify-between  h-full flex-grow overflow-y-scroll flex-col  shrink-0 basis-[300px] relative whitespace-normal w-[300px] box-border align-top pb-2 scroll-m-2">

                <div className={`gap-y-0  relative  flex-grow  items-start flex-wrap mx-1  py-1 px-2  `} >
                    <ColomnHeader title={t("app.catalog.primary_combination")} />
                    {
                        (primaryConfiguration?.length ?? []) > 0 && (
                            primaryConfiguration.map((encode, index) => (
                                <div key={index}>

                                    {
                                        encode.company_sub_attribute && (
                                            <CombinationCard
                                                type={encode.type}
                                                description={encode.description}
                                                isActiveCard={isActiveCard === encode.id}
                                                onClick={() => { onOpenChildren(position, encode) }}
                                                name={encode.company_sub_attribute.name}
                                                code={encode.code}
                                            />
                                        )
                                    }

                                    {
                                        encode.type === 'incrementer' && (
                                            <CombinationCard
                                                type={encode.type}
                                                isActiveCard={isActiveCard === encode.id}
                                                onClick={() => { onOpenChildren(position, encode) }}
                                                name={encode.code}
                                                code={encode.start_from}
                                            />
                                        )
                                    }

                                </div>
                            ))
                        )
                    }

                </div>


            </div>
        </div>
    )

}

const CombinationColumn = ({ isLoading, title, configurations, onOpenChildren, position, isActiveCard }) => {


    return (
        <div className={` self-start shrink-0 block  h-full  whitespace-nowrap px-1.5 py-0`} >
            <div className="bg-solitude-400  rounded-lg shadow-md  flex justify-between  h-full flex-grow overflow-y-scroll flex-col  shrink-0 basis-[300px] relative whitespace-normal w-[300px] box-border align-top pb-2 scroll-m-2">

                <div className={`gap-y-0  relative  flex-grow  items-start flex-wrap mx-1  py-1 px-2  `} >


                    <LoaderWrapper isLoading={isLoading}>

                        <ColomnHeader title={title} />

                        {
                            (configurations?.length ?? []) > 0 && (
                                <>
                                    {configurations.map((encode, index) => (
                                        <div key={index}>

                                            {
                                                encode.company_sub_attribute && (
                                                    <CombinationCard
                                                        type={encode.type}
                                                        onClick={() => { onOpenChildren(position, encode) }}
                                                        name={encode.company_sub_attribute.name}
                                                        description={encode.description}
                                                        isActiveCard={isActiveCard === encode.id}
                                                        code={encode.code}
                                                    />
                                                )
                                            }

                                            {
                                                encode.type === 'incrementer' && (
                                                    <CombinationCard
                                                        type={encode.type}
                                                        onClick={() => { onOpenChildren(position, encode) }}
                                                        name={encode.code}
                                                        isActiveCard={isActiveCard === encode.id}
                                                        code={encode.start_from}
                                                    />
                                                )
                                            }
                                        </div>
                                    ))}
                                </>
                            )
                        }

                    </LoaderWrapper>




                </div>


            </div>
        </div>
    )

}


const ColomnHeader = ({title}) => {

    return(
        <div className="flex items-center justify-between  sticky top-0 bg-solitude-400">
            <div className=" my-2 ">
                <div className='text-blue-1000 font-semibold whitespace-pre-line' style={{ fontSize: '14px' }}>
                    {title}
                </div>
            </div>
        </div>
    )

}

const CombinationCard = ({ code, name, onClick, isActiveCard, type, description = '' }) => {

    const { t } = useTranslation();
    return (
        <div onClick={onClick} className={classNames(
            isActiveCard ? 'border bg-green-100 border-green-900/[0.2]  shadow-sm' : 'bg-white',
            'mt-3 mx-auto w-full border rounded-md group cursor-pointer'
        )}>
            <dl className={'flex items-center justify-between'}>
                <div className={"flex flex-col p-3 "}>
                    <div className={"flex items-center text-left"}>
                        <div>
                            <div className="text-sm text-gray-900">
                                <span>
                                    {name}
                                </span>
                            </div>
                            <div className="text-xs text-gray-500" style={{fontSize: '0.70rem'}}>
                                {type === 'incrementer' ? t("app.company_preference.sequential_number") : t("app.catalog.name")}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"text-right mr-3 flex items-center"}>
                    <div>
                        <div className="text-sm text-gray-900">
                            <span>
                                {code}
                            </span>
                        </div>
                        <div className="text-xs text-gray-500" style={{fontSize: '0.70rem'}}>
                            {type === 'incrementer' ? t("app.company_preference.next_sequence") : t("app.catalog.code")}
                        </div>
                    </div>
                    <ChevronRightIcon className=" ml-2 h-5 w-5"/>
                </div>
            </dl>
            <p className={'text-sm text-gray-900  -mt-2 mb-2 px-3'}>{description}</p>
        </div>
    )
}

const GenereteCode = ({
                          productDetail = null,
                          title,
                          configurationColumn,
                          onUpdateProductDetail, encodingData, onInsertCode }) => {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const [isLoadingConfiguration, setLoadingConfiguration] = useState(true);
    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [updateException, setUpdateException] = useState(false);

    const [generetedCode, setGeneretedCode] = useState(false);
    const [isCodeExists, setCodeExists] = useState(false);
    const [priductDetails, setPriductDetails] = useState(false);




    const generateProductReference = () => {
        let GeneretedCode = encodingData.code;
        if (configurationColumn && configurationColumn.length > 1) {
            // eslint-disable-next-line
            configurationColumn.map((configuration) => {

                if (configuration.childId && configuration.childId !== '') {
                    const confResult = configurationColumn.find(r =>
                        r.configuration.some(n => n.id === configuration.childId)
                    )?.configuration.find(n => n.id === configuration.childId);

                    if (confResult) {
                        if (confResult.type === 'incrementer') {
                            GeneretedCode = GeneretedCode + formatNumberWithLeadingZeros(confResult.code, confResult.start_from)
                        } else {
                            GeneretedCode = GeneretedCode + confResult.code
                        }
                    }

                }
            })
        }
        //encodingData.number_limit
        return GeneretedCode;
    }

    const generateCodeString = () => {
        let GeneretedCode = encodingData.code;
        if (configurationColumn && configurationColumn.length > 1) {
            // eslint-disable-next-line
            configurationColumn.map((configuration) => {

                if (configuration.childId && configuration.childId !== '') {
                    const confResult = configurationColumn.find(r =>
                        r.configuration.some(n => n.id === configuration.childId)
                    )?.configuration.find(n => n.id === configuration.childId);

                    if (confResult) {
                        if (confResult.type === 'incrementer') {
                            GeneretedCode = GeneretedCode + '{incremental}'
                        } else {
                            GeneretedCode = GeneretedCode + confResult.code
                        }
                    }

                }
            })
        }
        return GeneretedCode;
    }


    const generateCodeFormatted = () => {
        let GeneretedCode = encodingData.code;
        if (configurationColumn && configurationColumn.length > 1) {
            // eslint-disable-next-line
            configurationColumn.map((configuration) => {

                if (configuration.childId && configuration.childId !== '') {
                    const confResult = configurationColumn.find(r =>
                        r.configuration.some(n => n.id === configuration.childId)
                    )?.configuration.find(n => n.id === configuration.childId);

                    if (confResult) {
                        if (confResult.type === 'incrementer') {
                            GeneretedCode = GeneretedCode + "|-|{incremental}"
                        } else {
                            GeneretedCode = GeneretedCode + "|-|" + confResult.code
                        }
                    }

                }
            })
        }
        return GeneretedCode;
    }


    const updateProduct = async () => {
        if (loggedCompany && productDetail) {
            let data = {}
            data["id"] = productDetail.id;
            data["product_reference"] = generetedCode;
            data["generated_string"] = generateCodeString();
            data["encode_formatted"] = generateCodeFormatted();
            setUpdateLoading(true);
            setUpdateException(false);
            putProduct({ company_id: loggedCompany.id, product_id: productDetail.id, data })
                .then((response) => {
                    setUpdateLoading(false);
                    toast.success(t("app.products.update_success"))
                    onUpdateProductDetail(response);
                })
                .catch((e) => {
                    setUpdateException(e.message);
                    setUpdateLoading(false);
                });
        }


        if (onInsertCode && !productDetail) {
            onInsertCode(generateCodeString(), generetedCode, generateCodeFormatted());

        }
    };


    useEffect(() => {

        async function fetchData() {
            if (loggedCompany){
                setLoadingConfiguration(true);
                try {
                    const res = await putElement(getPathParam([COMPANY, loggedCompany.id, CHECK_ENCODE]), {
                        code: generateProductReference(),
                        string: generateCodeString()
                    });
                    if (res.status === 'exist' && res.next_code){
                        setGeneretedCode(res.next_code);
                    }

                    if (res.status === 'exist' && !res.next_code) {
                        setCodeExists(true);
                        setPriductDetails(res.product);
                        setGeneretedCode(generateProductReference());
                    }

                    if (res.status === 'not-exist') {
                        setGeneretedCode(generateProductReference());
                    }

                } catch (e) {
                    setLoadingConfiguration(false);
                } finally {
                    setLoadingConfiguration(false);
                }
            }

        }

        fetchData();

        // eslint-disable-next-line
    }, [loggedCompany]);



    return (
        <div className={` self-start shrink-0 block  h-full  whitespace-nowrap px-1.5 py-0`} >
            <div className="bg-solitude-400  rounded-lg shadow-md  flex justify-between  h-full flex-grow overflow-y-scroll flex-col  shrink-0 basis-[300px] relative whitespace-normal w-[300px] box-border align-top pb-2 scroll-m-2">

                <div className={`gap-y-0  relative  flex-grow  items-start flex-wrap mx-1  py-1 px-2  `} >


                    <LoaderWrapper isLoading={isLoadingConfiguration}>

                        <ColomnHeader title={title} />


                        <div className={classNames(
                            isCodeExists ? " border-red-400 text-red-900 bg-red-100"  : " border-green-400 text-green-900 bg-green-100",
                            "mt-5 flex justify-center mx-auto text-center  p-5 border-2 rounded-lg border-dashed   font-semibold text-xl"
                        )}>
                            {generetedCode}
                        </div>


                        {
                            isCodeExists && (
                                <p className="mt-3 text-sm text-red-900" style={{ fontSize: '0.70rem' }}>{t("app.catalog.product_error")}</p>
                            )
                        }


                        {
                            priductDetails && (
                                <div className="bg-white mt-3 p-2 w-full cursor-pointer rounded-md hover:border hover:border-gray-500">
                                    <a target="_blank" rel="noreferrer" href={getWebPathParam([CATALOG, PRODUCT, priductDetails.id, PRODUCT_DETAIL])}>
                                        <ProductCell product={priductDetails} isActiveManufacture={false} />
                                    </a>
                                </div>

                            )
                        }



                        {/* Warning */}
                        {updateException && <Warning message={updateException} />}



                        {
                            !isCodeExists && (
                                <div className="mt-3">
                                    <SimpleButtonSubmit
                                        onClick={() => {
                                            updateProduct()
                                        }}
                                        isFullWith={true}

                                        isLoading={isUpdateLoading}
                                        label={t("app.catalog.add_code")}
                                    />
                                </div>
                            )
                        }


                    </LoaderWrapper>




                </div>


            </div>
        </div>
    )

}


const ErrorCombinationColumn = ({ code, isLoading, encodingData }) => {

    const { t } = useTranslation();
    return (
        <div className={` self-start shrink-0 block  h-full  whitespace-nowrap px-1.5 py-0`} >
            <div className="bg-solitude-400  rounded-lg shadow-md  flex justify-between  h-full flex-grow overflow-y-scroll flex-col  shrink-0 basis-[300px] relative whitespace-normal w-[300px] box-border align-top pb-2 scroll-m-2">

                <div className={`gap-y-0  relative  flex-grow  items-start flex-wrap mx-1  py-1 px-2  `} >


                    <LoaderWrapper isLoading={isLoading}>
                        <Warning message={t("app.catalog.limit_error_desc", { charLimit: encodingData.number_limit, code: code })} />
                    </LoaderWrapper>

                </div>


            </div>
        </div>
    )

}



export default GenerateEncoding;
