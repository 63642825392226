import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {getPermission, permissionGroup, permissionSpecific} from "../../../constants/permissions";
import { useTranslation } from "react-i18next";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";

import { useLocation, useNavigate } from "react-router-dom";
import { fetchCountries, fetchPorts } from "../../../reducers/configReducer";
import { Controller, useForm } from "react-hook-form";
import InputPortCountrySelect from "../../../components/common/form/input-port-country-select";
import InputVariantDate from "../../../components/common/form/input-variant-date";
import PalletIcon from "../../../resources/icons/PalletIcon";
import ContainerIcon from "../../../resources/icons/ContainerIcon";
import { RadioGroup } from "@headlessui/react";
import InputVariantToggle from "../../../components/common/form/input-variant-toggle";
import { getWebPathParam, serializeArray } from "../../../utils/converter";
import { LOGISTICS, RATE_SEARCH } from "../../endpoints";
import { classNames } from "../../../utils/Utils";




const Rates = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

 


  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchPorts());
  }, [dispatch]);


  const onSubmit = async (data) => {
    navigate(getWebPathParam([LOGISTICS, RATE_SEARCH]) + serializeArray([
      { type: data?.type ?? null }, 
      { departure_date: data?.departure_date ?? null }, 
      { from_place: data?.from?.type ?? null }, 
      { to_place: data?.to?.type ?? null }, 
      { from: data?.from?.code ?? null },
      { from_name: data?.from?.name ?? null }, 
      { to: data?.to?.code ?? null }, 
      { to_name: data?.to?.name ?? null },
      { nearest_port: data?.nearestPort ?? false }
    ]));
  };


  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FORWARDER_RATE, permissionSpecific.SEATCH_RATES)]} renderBaseOnFail={true}>
      <div className="h-[100%]">
        <div className="bg-white py-24 sm:py-32 relative h-full overflow-hidden">


          <div className="mx-auto max-w-7xl px-6 lg:px-8   ">


            <div className="mx-auto max-w-4xl sm:text-center">
              <h2 className="text-pretty text-5xl font-semibold tracking-tight text-blue-1000 sm:text-balance sm:text-6xl">
                Logistics Explorer
              </h2>
              <p className="mx-auto mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                To see the best market rates, please input place of loading, place of discharge, select the transport mode and click 'Search'
              </p>
            </div>
            <RatesSearchForm onSubmit={onSubmit} />
          </div>
        </div>
      </div>
      
    </BasePermissionWrapper>
  );
};


export const RatesSearchForm = ({ onSubmit, formClass = '', isMainSearch = true }) => {

  const { t } = useTranslation();
  const location = useLocation();

  const combinationTypes = [
    { type: 'pallet', name: '', icon: PalletIcon },
    { type: 'container', name: '', icon: ContainerIcon },
  ].filter(Boolean);
  const [combinationType, setCombinationType] = useState(combinationTypes[0])


  const {
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!isMainSearch){
      const searchParams = new URLSearchParams(location.search);
      const from = searchParams.get('from');
      const fromName = searchParams.get('from_name');
      const fromPlace = searchParams.get('from_place');
      const to = searchParams.get('to');
      const toName = searchParams.get('to_name');
      const toPlace = searchParams.get('to_place');
      const departureDate = searchParams.get('departure_date');
      const type = searchParams.get('type');
      setValue('from', { id: "from", type: fromPlace, code: from, name: fromName });
      setValue('to', { id: "to", type: toPlace, code: to, name: toName });
      setValue('departure_date', departureDate);
      setValue('type', type);
      setCombinationType(combinationTypes?.find(r => r.type === type) ?? combinationTypes[0]);
    }
    // eslint-disable-next-line
  }, [location, isMainSearch, setValue]);




  
  return(
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={formClass}>
        <div className={classNames(
          "mx-auto  max-w-2xl rounded-3xl bg-blue-1000 ring-1 ring-gray-200  lg:mx-0 lg:flex lg:max-w-none",
          isMainSearch ? 'mt-16'  : ''
        )}>


          <div className="p-4 lg:flex-auto ">
            
              <div className={classNames(
                isMainSearch ? 'rounded-2xl  ring-1 ring-inset ring-gray-900/5' : 'ring-1 ring-inset ring-gray-900/5  mx-auto lg:flex lg:max-w-7xl'
              )}>
              <div className=" mx-auto grid grid-cols-1 gap-2   sm:grid-cols-4 2xl:grid-cols-8">

                <div className="col-span-2">
                  <InputPortCountrySelect
                    label={"Partenza"}
                    placeholder={t("app.shippings.departure_port")}
                    selectedItem={watch('from')}
                    onSelect={(e) => {
                      setValue('from', e)
                    }}
                  />
                </div>

                <div className="col-span-2">
                  <InputPortCountrySelect
                    label={"Arrivo"}
                    placeholder={t("app.shippings.departure_port")}
                    selectedItem={watch('to')}
                    onSelect={(e) => {
                      setValue('to', e)
                    }}
                  />
                </div>

                <div className="col-span-2">
                  <Controller
                    name="departure_date"
                    rules={{ required: false }}
                    control={control}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <InputVariantDate
                        label={t("app.fulfillment.expected_date")}
                        format={"YYYY-MM-D"}
                        placeholder={"Es. 01/12/2021"}
                        activeMinDate={false}
                        startDate={value}
                        disabled={false}
                        isShowTime={false}
                        errors={error}
                        name={name}
                        onChange={(e) => {
                          setValue('departure_date', e);
                          onChange(e);
                        }}
                      />
                    )}
                  />
                </div>

                <fieldset className="border  w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm">

                  <RadioGroup value={combinationType} onChange={(e) => {
                    setCombinationType(e);
                    setValue('type', e.type);
                  }} className="p-1 flex items-center h-full  w-36 ">
                    {combinationTypes.map((option) => (
                      <RadioGroup.Option
                        key={option.name}
                        value={option}
                        className={({ active, checked }) =>
                          `cursor-pointer flex items-center justify-center h-full    w-full p-1 text-sm  rounded-md font-semibold     sm:flex-1
                                ${(combinationType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                        }
                      >
                        <option.icon className="h-5 w-5" />
                      </RadioGroup.Option>
                    ))}
                  </RadioGroup>
                </fieldset>


                <input type="submit"
                  className={"btn text-sm bg-white border border-gray-300 shadow-sm hover:bg-gray-100 text-gray-900 cursor-pointer"}
                  value={'Cerca Tariffa'}
                />
              </div>
            </div>

            {
              isMainSearch && (
                <div className="mx-auto  max-w-2xl   lg:mx-0 lg:flex lg:max-w-none flex justify-start   mt-3" >
                  <div className="text-left">
                    <Controller
                      name={"nearestPort"}
                      rules={{ required: false }}
                      control={control}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <InputVariantToggle
                          label={'Vuoi includere anche i porti vicini?'}
                          onChange={(e) => {
                            onChange(e)
                          }}
                          defaultChecked={value}
                          errors={error}
                        />
                      )}
                    />
                  </div>
                </div>
              )
            }

           

          </div>
        </div>
      </form>
    </>
  )
}


export default Rates;
