export const permissionType = {
  VIEW: "view",
  LIST: "list",
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
  MENU: "menu",
};

export const permissionGroup = {
  QUOTATION: "quotation", // OK
  LEAD: "lead", // OK
  ORDER: "order", // OK
  SHIPPING: "shipping", // OK
  FULFILLMENT: "fulfillment", // OK
  BOOKING: "booking", // OK
  FORWARDER_RATE: "rates", // OK
  CONTAINER: "container", // OK
  DOCUMENT: "document", // OK
  EVENT: "event", // OK
  CUSTOMER: "customer", // OK
  SUPPLIER: "supplier",
  PRODUCT: "product", // OK
  PRODUCT_RATE: "product_rate", // OK
  PRODUCT_SUPPLIER: 'product_supplier', // OK
  PRODUCT_PACKAGE: 'product_package', // OK
  PRODUCT_ATTACHMENT: 'product_attachment', // OK
  PRODUCT_TRANSLATION: 'product_translation', // OK
  PRODUCT_SETTING_CATEGORY: 'product_setting_category', // OK
  PRODUCT_SETTING_BRAND: 'product_setting_brand', // OK
  PRODUCT_SETTING_MANUFACTURE: 'product_setting_manufacture', // OK
  PRODUCT_SETTING_ATTRIBUTE: 'product_setting_attribute', // OK
  PRODUCT_SETTING_ENCODING: 'product_setting_encode', // OK
  PRODUCT_SETTING_SUPPLIER: 'product_setting_supplier', // OK
  FORWAREDER_TRAFFIC_LINE: "forwarder_traffic_line", // OK
  USER: "user",
  COMPANY_FORWARDER: "company_forwarder", // OK
  COMPANY_CONFIGURATION: "company_configuration", // OK
  COMPANY_TASK_PERMISSION: "company_task" // OK
};

export const permissionSpecific = {
  // Shipping OK
  SHIPPING_CLOSE: "close",
  SHIPPING_CUSTOMER_EDIT: "customer_edit",
  SHIPPING_ACTIVE_TRACKING: "active_tracking",

  // Container
  CONTAINER_DRAFT_LOADING: 'draft_loading', // OK
  CONTAINER_GALLERY_DOWNLOAD: 'gallery_download', // OK
  CONTAINER_LOAD_CONFIGURATION: 'load_configuration', // Require product_list POK
  CONTAINER_PACKING_LIST: 'packing_list', // Require container_load_configuration OK
  CONTAINER_GENERATE_DOCUMENT: 'generate_document_list', // OK
  CONTAINER_CONFIRM_LOADING: 'confirm_loading', // OK
  CONTAINER_IMPORT_PRODUCT: 'import_product_data', // OK

  // Document
  DOCUMENT_SEND_MAIL:'send_mail',
  DOCUMENT_NOTIFY_CUSTOMER:'notify_customer',
  DOCUMENT_GENERATE:'generate',
  DOCUMENT_PRIVATE:'private',
  DOCUMENT_AUTO_REMOVE:'auto_remove',
  DOCUMENT_SEND_NOTIFICATION:'send_notification',

  //Event
  EVENT_CONFIRM: 'confirm',

  //PRODUCTS
  PRODUCT_ARCHIVE: 'archive',
  PRODUCT_IMPORT: 'import_data',
  PRODUCT_EXPORT: 'export_data',
  PRODUCT_CATEGORY: 'category',
  PRODUCT_GENERATE_ENCODE: 'generate_encode',
  PRODUCT_LOG: 'log',
  PRODUCT_ALTERNATIVE: 'alternative',
  PRODUCT_RATE_SELLING: 'rate_selling',
  PRODUCT_GENERATE_FILE: 'generate_file',
  PRODUCT_MANAGE_STATUS: 'confirm',

  // Config
  COMPANY_CONF_WAREHOUSE: 'warehouse',
  COMPANY_CONF_MEDIA: 'media',
  COMPANY_CONF_IMPORT_MODEL: 'import_model',
  COMPANY_CONF_EMAIL_INTEGRATION: 'email_integration',
  COMPANY_CONF_JOB_TYPES: 'job_types',
  COMPANY_CONF_DOCUMENT_NUMBERING: 'document_numbering',
  COMPANY_CONF_WEBHOOK: 'webhook',
  COMPANY_CONF_DOCUMENT_TEMPLATE: 'document_template',
  COMPANY_CONF_SWITCH: 'switch_type',
  COMPANY_CONF_PERMISSIONS: 'permissions',
  COMPANY_CONF_EMAIL_TEMPLATE: 'email_template',


  //Customers
  CUSTOMER_ARCHIVE: 'archive',
  CUSTOMER_REVOKE: 'revoke_connection',
  CUSTOMER_SEND_MAIL: 'send_mail',
  CUSTOMER_BI: 'customer_bi',

  //Suppliers
  SUPPLIER_ARCHIVE: 'archive',
  SUPPLIER_REVOKE: 'revoke_connection',
  SUPPLIER_SEND_MAIL: 'send_mail',
  SUPPLIER_BI: 'customer_bi',

  // QUOTATION SPECIFIC
  QUOTATION_CLONE: 'clone', // OK
  QUOTATION_MARGIN_DATA: 'margin_data', //  ok
  QUOTATION_HIDE_PRODUCT: 'hide_product_data',  // OK
  QUOTATION_SELLING_HISTORY: 'selling_history_data', // OK
  QUOTATION_PURCHASE_HISTORY: 'purchase_history_data',  // OK
  QUOTATION_CREATE_PRODUCT: 'create_product_data',  // OK
  QUOTATION_IMPORT_PRODUCT: 'import_product_data',  // OK
  QUOTATION_GENERATE_DOCUMENT: 'generate_document_data', // OK
  QUOTATION_DOCUMENT_SENS_DATA: 'document_sensitive_data', // later
  QUOTATION_CONFIRM: 'confirm', // importer
  QUOTATION_CREATE_PRODUCT_ON_CATALOG: 'create_product_catalog', // OK
  QUOTATION_PRODUCT_CONFIRMATION: 'quotation_product_confirmation',  // OK

  // ORDER
  ORDER_IMPORT_PRODUCT: 'import_product_data',

  // LEAD
  LEAD_SEND_MAIL: 'send_mail',
  LEAD_IMPORT: 'import_data',
  LEAD_EXPORT: 'export_data',

  // RATES
  SEATCH_RATES: "search_rates" // OK
};

export const pg = {
  QUOTATION: "quotation",
  ORDER: "order",
  ORDER_PRODUCT: "order_product",
  SHIPPING: "shipping",
  CONTAINER: "container",
  DOCUMENT: "document",
  EVENT: "event",
  CUSTOMER: "customer",
  SUPPLIER: "supplier",
  PRODUCT: "product",
  PRODUCT_PRICE: "product_price",
  CATEGORY: "category",
  USER: "user",
  FORWARDER: "forwarder",
};

// Permission Types
export const pt = {
  VIEW: "view",
  LIST: "list",
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
  IMPORT: "import",
};

export const sp = {
  //package  internal
  ORDER_INTERNAL_PACKAGE: "int_order",
  QUOTATION_INTERNAL_PACKAGE: "int_order",
  QUOTATION_QUOTATION_PACKAGE: "int_quotation",
  LEAD_PACKAGE: "int_lead",
  FULFILLMENT_INTERNAL_PACKAGE: "int_fulfillment",
  SHIPPING_INTERNAL_PACKAGE: "int_shipping",
  CONNECTION_INTERNAL_PACKAGE: "int_connection",
  CATALOG_INTERNAL_PACKAGE: "int_catalog",
  EMPLOYEE_INTERNAL_PACKAGE: "int_employee",
  AGENT_INTERNAL_PACKAGE: "int_agent",
  FORWARDER_INTERNAL_PACKAGE: "int_forwarder",
  BOOKING_INTERNAL_PACKAGE: "int_booking",
  //package marketplace
  IMPORT_PRODUCT_MARKETPLACE_PACKAGE: "mp_import_product",
  IMPORT_ORDER_MARKETPLACE_PACKAGE: "mp_import_order",
  IMPORT_API_MARKETPLACE_PACKAGE: "mp_import_api",
  PACKING_LIST_MARKETPLACE_PACKAGE: "mp_packing_list",
  PERSONALIZED_NOTIFICATION_MARKETPLACE_PACKAGE: "mp_personalized_notification",
  TASK_MARKETPLACE_PACKAGE: "mp_task",
  GENERATE_DOCUMENT_MARKETPLACE_PACKAGE: "mp_generate_document",
  EMAIL_INTEGRATION_MARKETPLACE_PACKAGE: "mp_email_integration",
};

export const getPermission = (g, t) => `${g}.${t}`;
