import React from "react";

export default function InputRangeFilter({ min, max, defaultValue, input, className = '', handleRangeChange }) {

  return (
      <div className="relative pt-1">
        <input
            type="range"
            className={className}
            min={min}
            max={max}
            defaultValue={defaultValue ? defaultValue : min}
            {...input}
            onMouseUp={(e) => handleRangeChange(e.target.value)} // Quando l'utente rilascia il mouse
            onTouchEnd={(e) => handleRangeChange(e.target.value)} // Quando l'utente rilascia il dito
        />
      </div>
  );
}
