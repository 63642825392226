import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import PageHeader, {ActionButton} from "../../components/layout/page-header";
import { useSelector } from "react-redux";
import ActionsMenu from "../../components/common/table/actions-menu";
import {QUOTATION_OVERVIEW, SALES} from "../endpoints";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import AddQuotation from "./add-quotation";
import { useTranslation } from "react-i18next";
import CustomerCell from "../../components/common/table/customer-cell";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType} from "../../constants/permissions";
import ListSection from "../../components/layout/list-section";
import NumberFormat from "react-number-format";
import {getCustomFormat} from "../../utils/timeUtils";
import OptionalLink from "../../components/common/table/optional-link";
import CardDetail from "../../components/common/list/card-detail";
import TableDetail from "../../components/common/list/table-detail";
import {COMPANY, QUOTATION} from "../../api/endpoints";
import RemovePopup from "../../components/common/popup/remove-popup";
import {PlusIcon} from "@heroicons/react/outline";
import { getQuotationsDynamic} from "../../api/quotation";
import {StatusBarIcon} from "../../components/common/alert/status-bar-icon";
import DynamicFilterMenu from "../../components/common/table/dynamic-filter-menu";
import {generateParamsFormLocation} from "../../constants/dynamic-filter";
import CloneQuotation from "./clone-quotation";
import BasePermissionWrapper, {
    hasPermission,
    hasPermissionFor
} from "../../components/partials/restricted/base-permission-wrapper";
import UserInitial from "../../components/common/table/user-initial";
import UsersToolbar from "../../components/common/card/users-toolbar";
import {AcquisitionBar} from "../../components/common/alert/acquisition-bar";
import AnimatedPing from "../../components/common/progress/animated-ping";

function checkDate(data) {
    let today = new Date();
    let checkDate = new Date(data);
    let diff = today - checkDate;

    let days = diff / (1000 * 3600 * 24);


    if (days >= 8) {
        return "red";
    } else if (days >= 4) {

        return "";
    } else {
        return false;
    }
}

const Quotations = () => {

  const location = useLocation();

  const { loggedCompany, isExporter, companyType } = useSelector((state) => state.user);
  const { permissions } = useSelector((s) => s.userCompany);

  const canView =  hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionType.VIEW);
  const canCreate =  hasPermission(permissions, permissionGroup.QUOTATION, permissionType.CREATE, companyType, true);
  const canRemove =  hasPermission(permissions, permissionGroup.QUOTATION, permissionType.DELETE, companyType, true);
  const isActiveClone = hasPermission(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_CLONE, companyType, true);



  const [isFetchQuotationsLoading, setFetchQuotationsLoading] = useState(true);
  const [limit] = useState(30);
  const [quotationList, setQuotationList] = useState({quotation: [], total_rows: 0});

  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
        "page"
    );

    if(pageNumber){
        return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }

  const [offset, setOffset] = useState(checkOffset());
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [needRefresh, setNeedRefresh] = useState(false);

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  const [isOpenClone, setOpenClone] = useState(false);
  const [cloneDetail, setCloneDetail] = useState({});


  const [dynamicFilters, setDynamicFilters] = useState(false);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
  const [defaultParams, setDefaultParams] = useState(false);
  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [lastCompanyType, setLastCompanyType] = useState(companyType);
  const [totals, setTotals] = useState([]);

  const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});




  const { t } = useTranslation();

    const columns = (isExporter || isExporter === null) ?
            [
                {
                    header:  t("app.common.customer"),
                    id: 'business_name',
                    accessor: r => r.customer.business_name,
                },
                {
                    header: t("app.quotations.price"),
                    id: 'price',
                    accessor: r => r?.totals?.total_discounted ?? 0,
                },
                {
                    header: t("app.quotations.acquisition_status"),
                    id: 'acquisition_status',
                    accessor: "acquisition_status"
                },
                {
                    header: t("app.quotations.status"),
                    id: 'status',
                    accessor: "status"
                },
                {
                    header: t("app.users.users"),
                    id: 'quotation_date',
                    accessor: null
                },
                {
                    header: t("app.quotations.last_contact"),
                    id: 'last_contact',
                    accessor: r => new Date(r.last_contact).getTime()
                },
                {
                    header: "",
                    accessor: null
                }
            ] : [
                {
                    header:  t("app.common.supplier"),
                    id: 'business_name',
                    accessor: r => r.customer.business_name,
                },
                {
                    header: t("app.quotations.reference"),
                    id: 'quotation_reference',
                    accessor: "quotation_reference"
                },
                {
                    header: t("app.quotations.price"),
                    id: 'price',
                    accessor: r => r?.totals?.total_discounted ?? 0,
                },
                {
                    header: t("app.quotations.status"),
                    id: 'status',
                    accessor: "status"
                },
                {
                    header: t("app.quotations.expire_date"),
                    id: 'expire_date',
                    accessor: r => new Date(r.expire_date).getTime()
                },
                {
                    header: "",
                    accessor: null
                }
            ];


   useEffect(() => {
       if(companyType !== lastCompanyType){
           setDynamicDateFilter([]);
           setDefaultParams(false);
           setDefaultParamsUsed(false);
       }
       // eslint-disable-next-line
   }, [companyType]);





    useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchQuotations() {
      if (loggedCompany) {
        setFetchQuotationsLoading(true);
          setTotals([]);
          try {
           const res = await getQuotationsDynamic({
            company_id: loggedCompany.id,
            params: {
              limit: activeDynamicFilter.length > 0 ? 1000000 : limit,
              offset: query.length > 0 ? 0 : offset,
              query: query,
              status: null,
              remove_default: !defaultParamsUsed,
              company_type: companyType
            },
            data: activeDynamicFilter,
            signal,
          });
            setQuotationList(res);
            setLastCompanyType(companyType);
            if(res.filters){
                setDynamicFilters(res.filters);
            }
            if(res.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)){
                setDefaultParams('?'+res.default_filter);
            }




            if(activeDynamicFilter.length > 0 && res.quotation.length > 0){
                const totalsAmounts = res.quotation.reduce((accumulator, current) => {
                    let symbol = current?.currency?.currency_symbol ?? '€';
                    let total = current?.totals?.total_discounted  ?? 0;

                    const found = accumulator.find(item => item.symbol === symbol);

                    if (found) {
                        found.total += total;
                    } else {
                        accumulator.push({ symbol: symbol, total: total });
                    }

                    return accumulator;
                }, []);
                setTotals(totalsAmounts);
            }


        } catch (e) {
            setQuotationList({ quotation: [], total_rows: 0 });
            if(signal.aborted){
                setFetchQuotationsLoading(true);
            }
        } finally {
            if(signal.aborted){
                setFetchQuotationsLoading(true);
            }else{
                setFetchQuotationsLoading(false);
            }
        }
      }
    }

      fetchQuotations();
    return () => {
      controller.abort();
    };
        // eslint-disable-next-line
  }, [offset, query, loggedCompany, companyType, needRefresh, activeDynamicFilter, limit]);

  return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.QUOTATION, permissionType.LIST)]} renderBaseOnFail={true}>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.quotations.quotations")} canSwitch={true} >
                  {
                      canCreate && (
                          <ActionButton
                              icon={
                                  <PlusIcon
                                      className="w-4 h-4 flex-shrink-0"
                                      aria-hidden="true"
                                  />
                              }
                              onClick={() => {
                                  setIsOpen(true);
                              }}
                              text={t("app.quotations.new_quotation")}
                          />
                      )
                  }
          </PageHeader>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


          <ListSection
            pageLimit={activeDynamicFilter.length > 0 ? 2000 : limit}
            title={isExporter || isExporter === null ? t("app.common.customer") : t("app.common.supplier")}
            columns={columns}
            totalRows={quotationList.total_rows}
            list={quotationList?.quotation ?? []}
            onSearch={ (r) => {
                setOffset(0);
                setQuery(r);
            }}
            isLoading={isFetchQuotationsLoading}
            paginationOnChange={setOffset}
            isDynamicPagination={true}
            isDynamicSearch={true}
            mobileRow={MobRow}
            desktopRow={DeskRow}
            isTableSort={true}
            sortBy={sortBy}
            onSortBy={(n) => {
                setSortBy(n);
            }}
            activeDynamicFilter={true}
            extras={{
                isExporter,
                canView,
                canRemove,
                isActiveClone,
                onRemove: (r, detail) => {
                    setOpenRemove(r);
                    setRemoveDetail(detail);
                },
                onClone: (detail) => {
                    setOpenClone(true);
                    setCloneDetail(detail);
                }
            }}
          >

          <DynamicFilterMenu
              filters={dynamicFilters}
              defaultParams={defaultParams}
              lastCompanyType={lastCompanyType}
              isLoading={isFetchQuotationsLoading && offset === 0}
              filterType={'quotation'}
              onChange={(r) => {
                  setDynamicDateFilter(r);
                  setOffset(0);
                  const querySearch = new URLSearchParams(window.location.search).get("querySearch");
                  if (querySearch) {
                      setQuery(querySearch);
                  } else {
                      setQuery('');
                  }
                  setDefaultParamsUsed(true);
                  setDefaultParams(false);
              }}
          />
          </ListSection>
        </div>
      </div>

        {(canRemove && isOpenRemove) && (
            <RemovePopup
                isOpen={isOpenRemove}
                setIsOpen={setOpenRemove}
                detail={removeDetail}
                onRemoveConfirmed={() => {
                    setNeedRefresh(!needRefresh);
                }}
            />
        )}

        {(isActiveClone && isOpenClone) && (
            <CloneQuotation
                isOpen={isOpenClone}
                setIsOpen={setOpenClone}
                detail={cloneDetail}
            />
        )}

      {(canCreate && isOpen) && <AddQuotation isOpen={isOpen} setIsOpen={setIsOpen} />}


          {
              !isFetchQuotationsLoading && totals.length > 0 && (
                  <StickyDiv totals={totals} />
              )
          }

    </BasePermissionWrapper>
  );
};


const StickyDiv = ({totals}) => {
    const { t } = useTranslation();



    return (
        <div id="stickyDiv"
            className={`sticky -bottom-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 z-10`}
        >
            <div className="overflow-x-auto shadow-2xl" style={{boxShadow: '0px -3px 15px #00000026'}}>
                <div className="align-middle inline-block w-24 min-w-full mx-auto">
                    <table className="min-w-full divide-y divide-gray-200 scrollableSticky ">
                <thead className={"bg-white  "}>
                <tr>
                    <th className="px-6 py-4 text-left text-xs font-medium  uppercase tracking-wider w-80 shadow-xl" >
                        {t("app.quotations.total_quotation")}
                    </th>

                    {
                        totals.map((total, index) => (
                            <th key={index} className="px-6 py-4 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider border-r last:border-r-0 shadow-xl">
                                <NumberFormat
                                    value={total.total}
                                    displayType={"text"}
                                    decimalScale={2}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={total.symbol  + " "}
                                />
                            </th>
                        ))
                    }


                </tr>
                </thead>
            </table>
                </div>
            </div>
        </div>
    );
};

const MobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

  const removeDetail = {
      title: t("app.quotations.remove_title"),
      message: t("app.quotations.remove_description"),
      endpoint: getPathParam([COMPANY, loggedCompany.id , QUOTATION, element.id])
  };



  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
        isLinked={extras.canView}
        to={getWebPathParam([SALES, QUOTATION, element.id, QUOTATION_OVERVIEW])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">
            <CustomerCell
                customer={element.customer}
                extra={(element.internal_note && extras.isExporter) ? element.internal_note : element.customer_note}
            />
          <ActionsMenu>

              {(extras.canView) && (
                  <Link to={getWebPathParam([SALES, QUOTATION, element.id, QUOTATION_OVERVIEW])}>
                      {t("app.common.overview")}
                  </Link>
              )}

              {extras.isActiveClone && (
                  <button className={'text-left'} onClick={() => {  extras.onClone(element);}}>
                      {t("app.common.clone")}
                  </button>
              )}

              {extras.canRemove && !element.is_closed && (
                  <button className={'text-left'} onClick={() => {extras.onRemove(true, removeDetail); }}>
                      {t("app.common.remove")}
                  </button>
              )}

          </ActionsMenu>
        </div>

        <div className="flex flex-col">

            <CardDetail title={t("app.quotations.reference")}>
                {element.quotation_reference}
            </CardDetail>


            {
                extras.isExporter && (
                    <CardDetail title={t("app.quotations.last_contact")} extraclass={'relative'}>
                        {
                            (element.last_contact && checkDate(element.last_contact) !== false) && (
                                <AnimatedPing color={checkDate(element.last_contact)} extraClass={'-ml-5'} style={{marginTop: '4.1px'}} />
                            )
                        }
                        {getCustomFormat(element.last_contact, 'DD/MM/YYYY')}
                    </CardDetail>
                )
            }


            <CardDetail title={t("app.quotations.price")}>
                <NumberFormat
                    value={element?.totals?.total_discounted ?? 0}
                    displayType={"text"}
                    decimalScale={2}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={(element?.currency?.currency_symbol ?? '') + " "}
                />
            </CardDetail>


            {
                extras.isExporter && (
                    <CardDetail title={t("app.quotations.acquisition_status")}>
                        <AcquisitionBar status={element.acquisition_status} />
                    </CardDetail>
                )
            }

            <CardDetail title={t("app.quotations.status")}>
                <StatusBarIcon status={element.status} />
            </CardDetail>

            <CardDetail title={t("app.quotations.confirm_document")}>
                {getCustomFormat(element.confirmed_date, 'DD/MM/YYYY')}
            </CardDetail>


            {
                extras.isExporter && (element?.users ?? []).length > 0 && (
                    <CardDetail title={t("app.users.users")}>
                        <UsersToolbar users={element?.users ?? []} isActive={(element?.users ?? []).length > 1}>
                            <div className="flex items-center space-x-2">
                                <div className={element.users.length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>


                                    {
                                        element.users.length === 1 && (
                                            <div className="text-right mr-1">

                                                <div className="-mb-1 text-xs flex-wrap text-gray-500">
                                                    {t("app.quotations.creator")}
                                                </div>
                                                <div className="truncate text-sm text-gray-900">
                                                    {element.users[0]?.fullname ?? ''}
                                                </div>
                                            </div>
                                        )
                                    }

                                    {(element.users.length >= 4 ? element.users.slice(0, 4) : element.users).map((user, index) => (
                                        index === 0 ? (
                                            <UserInitial user={user} ringClass={" ring-yellow-400 "}/>
                                        ) : (
                                            <UserInitial user={user} />
                                        )

                                    ))}


                                </div>
                                {element.users.length > (element.users.length >= 4 ? element.users.slice(0, 4) : element.users).length ? (
                                    <span className="flex-shrink-0 text-xs font-medium leading-5">
                                +{element.users.length - (element.users.length >= 4 ? element.users.slice(0, 4) : element.users).length}
                              </span>
                                ) : null}
                            </div>
                        </UsersToolbar>
                    </CardDetail>
                )
            }


          <CardDetail title={extras.isExporter ? t("app.quotations.date") : t("app.quotations.expire_date")}>
            {extras.isExporter ? getCustomFormat(element.quotation_date, 'DD/MM/YYYY') : getCustomFormat(element.expire_date, 'DD/MM/YYYY')}
          </CardDetail>

        </div>
      </OptionalLink>
    </li>
  );
};

const DeskRow = ({ element , extras}) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

  const removeDetail = {
    title: t("app.quotations.remove_title"),
    message: t("app.quotations.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id , QUOTATION, element.id])
  };


  return (
    <tr key={element.id}>
      <TableDetail extraClass="max-w-sm">
        <div className="flex items-center space-x-3 lg:pl-2">
          <OptionalLink
              isLinked={extras.canView}
              to={getWebPathParam([SALES, QUOTATION, element.id, QUOTATION_OVERVIEW])}
              className="truncate hover:text-gray-600"
          >
              <CustomerCell
                  customer={element.customer}
                  extra={(element.internal_note && extras.isExporter) ? element.internal_note : element.customer_note}
                  secondExtra={(extras.isExporter) ? (<>{element.quotation_reference}</>) : false}
              />

          </OptionalLink>
        </div>
      </TableDetail>


        {
            !extras.isExporter && (
                <TableDetail>
                    {element.quotation_reference}
                </TableDetail>
            )
        }

      <TableDetail>
        <NumberFormat
            value={element?.totals?.total_discounted ?? 0}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            suffix={" " + (element?.currency?.currency_symbol ?? '')}
        />
      </TableDetail>

        {
            extras.isExporter && (
                <TableDetail>
                    <AcquisitionBar status={element.acquisition_status} />
                </TableDetail>
            )
        }



      <TableDetail>
         <StatusBarIcon status={element.status} />
          {
              extras.isExporter && element.confirmed_date && (
                  <div className="text-xs text-gray-500 mt-1" style={{fontSize: '11px'}}>
                      {
                          t("app.quotations.company_confirmed_document", {
                              date_confirm: getCustomFormat(element.confirmed_date, 'DD/MM/YYYY'),
                              time: getCustomFormat(element.confirmed_date, 'HH:mm')
                          })
                      }
                  </div>
              )
          }


          {
              !extras.isExporter && element.confirmed_date && (
                  <div className="text-xs text-gray-500 mt-1" style={{fontSize: '11px'}}>
                      {t("app.quotations.confirmed_document", {date: getCustomFormat(element.confirmed_date, 'DD/MM/YYYY'),
                          time: getCustomFormat(element.confirmed_date, 'HH:mm')
                      })}
                  </div>
              )
          }
      </TableDetail>


        <TableDetail>
            {extras.isExporter  && (element?.users ?? []).length > 0 ? (
                <UsersToolbar users={element?.users ?? []} isActive={(element?.users?? []).length > 1} extraData={getCustomFormat(element.quotation_date, 'DD/MM/YYYY')} className="relative">
                    <div className="flex items-center space-x-2">
                        <div className={element.users.length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>
                            {(element.users.length >= 4 ? element.users.slice(0, 4) : element.users).map((user, index) => (
                                index === 0 ? (
                                        <UserInitial user={user} ringClass={" ring-yellow-400 "}/>
                                    ) : (
                                        <UserInitial user={user} />
                                    )

                            ))}

                            {
                                element.users.length === 1 && (
                                    <div className="text-left ml-1">

                                        <div className="truncate text-sm text-gray-900">
                                            {element.users[0]?.fullname ?? ''}
                                        </div>
                                        <div className="-mt-1 text-xs flex-wrap text-gray-500" style={{fontSize: '11px'}}>
                                            {t("app.quotations.created_on", {date: getCustomFormat(element.quotation_date, 'DD/MM/YYYY')})}
                                        </div>
                                    </div>
                                )
                            }



                        </div>
                        {element.users.length > (element.users.length >= 4 ? element.users.slice(0, 4) : element.users).length ? (
                            <span className="flex-shrink-0 text-xs font-medium leading-5">
                                +{element.users.length - (element.users.length >= 4 ? element.users.slice(0, 4) : element.users).length}
                              </span>
                        ) : null}
                    </div>
                </UsersToolbar>
            ) : getCustomFormat(element.expire_date, 'DD/MM/YYYY')}
       </TableDetail>



        {
            extras.isExporter && (
                <TableDetail extraClass={'relative'}>
                    {
                        (element.last_contact && checkDate(element.last_contact) !== false) && (
                            <AnimatedPing color={checkDate(element.last_contact)} extraClass={'-ml-5'} style={{marginTop: '4.1px'}} />
                        )
                    }
                    {getCustomFormat(element.last_contact, 'DD/MM/YYYY')}
                </TableDetail>
            )
        }


      <TableDetail>
        <ActionsMenu>
          {(extras.canView) && (
              <Link to={getWebPathParam([SALES, QUOTATION, element.id, QUOTATION_OVERVIEW])}>
                {t("app.common.overview")}
              </Link>
          )}
            {extras.isActiveClone && (
                <button className={'text-left'} onClick={() => {  extras.onClone(element);}}>
                    {t("app.common.clone")}
                </button>
            )}
          {extras.canRemove && !element.is_closed && (
              <button className={'text-left'} onClick={() => {  extras.onRemove(true, removeDetail);}}>
                  {t("app.common.remove")}
              </button>
          )}
        </ActionsMenu>
      </TableDetail>

    </tr>
  );
};




export default Quotations;
