import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const InputVariantDate = ({ label, isRequired, name, startDate, cleanSearch = null, cleanData = null, disabled, selectsEnd = false, activeMinDate, minDate = null, onChange, isShowTime, errors, warning, warningRegex, format, extraClass = '', isShowLabel= true }) => {


    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if(startDate){
            let date = startDate ? new Date(moment(startDate).format(isShowTime ? "MM/DD/YYYY HH:mm" : 'MM/DD/YYYY')) : '';
            if(date !== '' && date !== 'Invalid Date'){
                setSelected(date);
            }
        }


        // eslint-disable-next-line
    }, [startDate]);


    useEffect(() => {
        if (cleanSearch !== null) {
            setSelected(null);
        }
    }, [cleanSearch]);


    useEffect(() => {
        if (cleanData ) {
            setSelected(null);
        }
    }, [cleanData]);

    const { t } = useTranslation();
    return (
        <div className="">
            <div className="border  w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm">
                <label className="py-2 pl-3 block text-sm font-medium -mb-3 text-gray-700">
                    {label}
                </label>
                <DatePicker
                    selected={selected}
                    disabled={disabled}
                    minDate={activeMinDate ? (minDate ? minDate : new Date()) : false}
                    onChange={(date) => {
                        setSelected(date);
                        onChange(date ? moment(date, 'date/utc format').format('YYYY-MM-DD') : '');
                    }}
                    showTimeInput={isShowTime}
                    selectsEnd={selectsEnd}
                    timeInputLabel="Time:"
                    timeCaption={'Orario'}
                    placeholderText={t("app.common.placeholder", { field: label.toLowerCase() })}
                    dateFormat={isShowTime ? "dd/MM/yyyy HH:mm" : 'dd/MM/yyyy'}
                    popperPlacement={'bottom-end'}
                    className={`form-input full border-none py-0.5 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed `}
                />
            </div>
        </div>
        

    )
};

export default InputVariantDate;
