// oauth
export const OAUTH_LOGIN = "oauth/login";
export const OAUTH_LOGOUT = "oauth/logout";
export const OAUTH_REGISTER = "oauth/register";

// User
export const GET_LOGGED_USER = "user/me";
export const USER = "user";
export const ME = "me";
export const DASHBOARD = "dashboard";
export const WIDGETS = "widgets";
export const WIDGET = "widget";
export const WIDGET_TYPES = "widget_types";
export const WIDGET_POSITION = "widget_position";

// verify
export const VERIFY_RESEND_MAIL = "verify/resend_mail";
export const VERIFY_USER_MAIL = "verify/user_mail";

// reset password
export const RESET_PASSWORD = "reset_password";

// Config
export const GET_COUNTRIES = "countries";
export const GET_UNITS = "units";
export const GET_WEBHOOK_EVENTS = "webhook_events";
export const GET_CITIES= "cities";
export const GET_REGIONS = "regions";
export const GET_MUNICIPALITY = "municipalities";
export const GET_PHONE_PREFIXES = "phone_prefixes";
export const GET_PORTS = "ports";
export const GET_VESSELS = "vessels";
export const GET_CURRENCIES = "currencies";
export const GET_DELIVERY_TYPES = "delivery_types";
export const GET_SHIPPING_TYPES = "shipping_types";
export const GET_TERM_TYPES = "term_types";
export const GET_SHIPPING_LINES = "shipping_lines";
export const GET_DOCUMENT_TYPES = "document_types";
export const GET_EVENT_TYPES = "event_types";
export const GET_CONTAINER_TYPES = "container_types";
export const GET_SIGNED_REQUESTS = "signed_requests";
export const GET_PERMISSIONS = "permissions";
export const GET_PERMISSION_SPECIFIC = "permission_specific";
export const GET_PACKAGING_TYPES = "packaging_types";
export const GET_NOTIFICATION_TYPES = "notification_groups";
export const GET_EMAIL_PROVIDERS = "email_providers";
export const GET_LANGUAGES = "languages";
export const PLACES = "places";

// Company
export const COMPANIES = "companies";
export const COMPANY = "company";
export const EXCHANGE = "exchange";
export const USERS = "users";
export const PREFIXES = "prefixes";
export const PREFIX = "prefix";
export const WEBHOOKS = "webhooks";
export const WEBHOOK = "webhook";
export const DOCUMENT_TEMPLATES = "document_templates";
export const DOCUMENT_TEMPLATE = "document_template";

export const DOCUMENT_TEMPLATE_CATEGORIES = "document_template_categories";
export const DOCUMENT_TEMPLATE_CATEGORY = "document_template_category";


export const COMPANY_MEDIA_CATEGORIES = "company_media_categories";
export const COMPANY_MEDIA_CATEGORY = "company_media_category";



export const COMPANY_ATTRIBUTE_CATEGORIES = "company_attribute_categories";
export const COMPANY_ATTRIBUTE_CATEGORY = "company_attribute_category";


export const LABEL_GENERATE = "label_generate";
export const FONTS = "fonts";
export const FONT = "font";
export const LOGS = "logs";
export const BASE = "base";
export const COMPANY_FILTERS = "company_filter";
// Customers
export const CUSTOMERS = "customers";
export const CUSTOMER = "customer";
export const SEND_CONNECTION = "send_connection";
export const CANCEL_CONNECTION = "cancel_request";
export const RESTORE = "restore";
export const ARCHIVE = "archive";

// Orders
export const ORDERS = "orders";
export const ORDER = "order";
export const ACTIVE_ORDERS = "active_orders";
export const ACTIVE_ORDER = "active_order";
export const ORDER_PRODUCTS = "order_products";
export const ORDER_PRODUCT = "order_product";

export const QUOTATIONS = "quotations";
export const QUOTATION = "quotation";
export const STATUS = "status";
export const CONFIRMED = "confirmed";

export const LEADS = "leads";
export const LEAD = "lead";



// Forwarders
export const FORWARDERS = "forwarders";
export const FORWARDER = "forwarder";
export const FORWARDER_EMAIL = "forwarder_email";
export const FORWARDER_EMAILS = "forwarder_emails";
export const FORWARDER_CONTACTS = "forwarder_contacts";
export const FORWARDER_CONTACT = "forwarder_contact";
export const FORWARDER_LINES = "forwarder_lines";
export const FORWARDER_LINE = "forwarder_line";
export const EMAIL_PREVIEW = "email_preview";
export const FULFILLMENT_NEGOTIATION = "fulfillment_negotiation";



// tasks
export const TASKS = "tasks";
export const MESSAGES = "messages";
export const MESSAGE = "message";
export const TASK = "task";
export const FILE = "file";

// Supplier
export const SUPPLIERS = "suppliers";
export const SUPPLIER = "supplier";

// Alternatives
export const ALTERNATIVES = "alternatives";
export const ALTERNATIVE = "alternative";


// Shippings
export const SHIPPINGS = "shippings";
export const SHIPPING = "shipping";
export const TRACKING = "tracking";
export const SHIPPING_IMPORTER = "shipping_importer";
export const IMPORTER = "importer";

// Container
export const CONTAINERS = "containers";
export const CONTAINER = "container";
export const GENERATE_PACKING_LIST = "generate_packing_list";
export const GENERATE_PACKING_LIST_SIMULATION = "generate_packing_list_simulation";
export const MEDIAS = "medias";
export const MEDIA = "media";

export const COMPANY_MEDIAS = "company_medias";
export const COMPANY_MEDIA = "company_media";

export const CONTAINER_PRODUCT = "container_product";
export const CONTAINER_PRODUCTS = "container_products";
export const PACKINGS = "packings";
export const PACKING = "packing";
export const AUTOMATIC = "automatic";

export const GROUPS = "groups";
export const GROUP = "group";
export const SUB_GROUP = "sub_group";
export const LOAD_ALL = "load_all";
export const CLONE = "clone";

// pallets
export const PALLETS = "pallets";
export const PALLET = "pallet";

// Document
export const PACKAGINGS = "packagings";
export const PACKAGING = "packaging";


// Document
export const DOCUMENTS = "documents";
export const DOCUMENT = "document";
export const DOCUMENT_SEND_NOTIFICATION = "document_send_notification";

// Event
export const EVENTS = "events";
export const EVENT = "event";
export const EVENT_TYPE = "event_type";

// Catalog
export const PRODUCTS = "products";
export const CATALOG_PRODUCTS = "catalog_products";
export const CATALOG_PRODUCT = "catalog_product";
export const CATALOG = "catalog";
export const PRODUCT = "product";
export const PRICE_HISTORY = "price_history";
export const SELLING_HISTORY = "selling_history";
export const PRODUCT_POSITIONS = "product_positions";
export const DIMENSION = "dimension";
export const DIMENSIONS = "dimensions";
export const CATEGORIES = "categories";
export const CATEGORY = "category";
export const SUB_CATEGORIES = "sub_categories";
export const SUB_CATEGORY = "sub_category";
export const BRANDS = "brands";
export const BRAND = "brand";
export const IMAGE = "image";
export const TRANSLATION = "translation";
export const TRANSLATIONS = "translations";
export const COMPANY_SUPPLIERS = "company_suppliers";
export const COMPANY_SUPPLIER = "company_supplier";
export const MANUFACTURES = "manufactures";
export const MANUFACTURE = "manufacture";
export const PRODUCT_LOG = "product_log";

export const COMPANY_ATTRIBUTES = "company_attributes";
export const COMPANY_ATTRIBUTE = "company_attribute";
export const COMPANY_SUB_ATTRIBUTES = "company_sub_attributes";
export const COMPANY_SUB_ATTRIBUTE = "company_sub_attribute";
export const PRODUCT_ENCCODES = "product_encodes";
export const PRODUCT_ENCCODE = "product_encode";
export const GENERATE_ENCODE = "generate_encode";
export const PREVIEW = "preview";
export const SUB_ENCODE_POSITION = "sub_encode_position";


export const PRODUCT_ENCODE_ATTRIBUTES = "product_encode_attributes";
export const PRODUCT_ENCODE_ATTRIBUTE = "product_encode_attribute";
export const CHECK_ENCODE = "check_encode";



export const PRICE_HISTORIES = "price_histories";
export const DATA_PRICE_HISTORIES = "data_price_histories";


// import
export const IMPORT = "import";
export const EXPORT = "export";
export const EXPORTS = "exports";
export const FILTERS = "filters";
export const COUNT = "count";
export const IMPORTS = "imports";
export const IMPORT_MODEL = "import_model";
export const IMPORT_MODELS = "import_models";

// notification
export const NOTIFICATIONS = "notifications";
export const NOTIFICATION_UNREAD = "notification_unread";
export const NOTIFICATION_READ = "notification_read";

// Connection
export const CONNECTION_VERIFY = "connection_verify";
export const COMPANY_CONNECTION_VERIFY = "company_connection_verify";
export const USER_CONNECTION_VERIFY = "user_connection_verify";
export const CONNECTIONS = "connections";
export const CONNECTION = "connection";
export const NOTES = "notes";
export const NOTE = "note";
export const LOG = "log";
export const ATTACHMENTS = "attachments";
export const ATTACHMENT = "attachment";
export const COMBINATIONS = "combinations";
export const COMBINATION = "combination";
export const NEGOTIATIONS = "negotiations";
export const NEGOTIATION = "negotiation";
export const REPLAY_EMAIL = "reply_email";
export const READ_EMAIL = "read_email";
export const VERIFY = "verify";
export const USER_CONNECTIONS = "user_connections";
export const USER_CONNECTION = "user_connection";
export const USER_PERMISSIONS = "user_permissions";
export const USER_PERMISSION = "user_permission";
export const CONNECTION_CATEGORIES = "connection_categories";
export const CONNECTION_CATEGORY = "connection_category";
export const NOTIFICATION = "notification";
export const ADDRESSES = "addresses";
export const ADDRESS = "address";


// warehouse
export const WAREHOUSE = "warehouse";
export const WAREHOUSES = "warehouses";

// email template
export const EMAIL_TEMPLATE = "email_template";
export const EMAIL_TEMPLATES = "email_templates";

// Company Permission Group
export const PERMISSION_GROUPS = "permission_groups";
export const PERMISSION_GROUP = "permission_group";
export const PERMISSION = "permission";


// report
export const REPORT = "report";
export const REPORT_TYPES = "report_types";
export const REPORT_TYPE = "report_type";
export const REPORT_GENERATE = "report_generate";
export const REPORT_CONFIRM = "report_confirm";
export const DOCUMENT_DOWNLAOD = "document_download";

// warehouse
export const SUBSCRIPTIONS = "subscriptions";
export const SUBSCRIPTION = "subscription";
export const SUBSCRIPTION_PAYMENT = "subscription_payment";
export const SUBSCRIPTION_CHANGE = "subscription_change";
export const SUBSCRIPTION_CONFIRM = "subscription_confirm";
export const SUBSCRIPTION_DOWNGRADE_CHECK = "subscription_downgrade_check";
export const CHECKOUT = "checkout";
export const PAYMENT_INTENT = "payment_intent";
export const OFFLINE_SECRET = "offline_secret";

export const SUBSCRIPTION_CANCEL = "subscription_cancel";
export const SUBSCRIPTION_ACTIVE = "subscription_active";
export const SUBSCRIPTION_PAY = "subscription_pay";
export const PROPOSAL = "proposal";
export const SUBSCRIPTION_BILLING = "subscription_billing";
export const SUBSCRIPTION_USAGE = "subscription_usage";

// email_provider
export const EMAIL_PROVIDER = "email_provider";
export const EMAIL = "email";
export const EMAIL_LIST = "mail_list";
export const SEND_EMAIL = "send_mail";
export const EMAIL_VERIFY = "email_verify";

