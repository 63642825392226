import React from "react";

export default function InputRange({ min, max, defaultValue, input, className = 'form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none' }) {

  return (
      <div className="relative pt-1">
        <input
            type="range"
            className={className}
            min={min}
            max={max}
            defaultValue={defaultValue ? defaultValue : min}
            {...input}
        />
      </div>
  );
}
