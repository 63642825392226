import React, { useEffect, useState } from 'react'
import ListSection from "../../../../components/layout/list-section";
import { useDispatch, useSelector } from "react-redux";
import { FilterButton, ImportButton } from "../../../../components/layout/page-header";
import { ArrowLeftIcon, ArrowRightIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import TableDetail from "../../../../components/common/list/table-detail";
import { useTranslation } from "react-i18next";
import CardDetail from "../../../../components/common/list/card-detail";
import { BOOKING,  COMPANY, FORWARDER, FORWARDERS_LINE, TRAFFIC_LINE } from '../../../endpoints';
import { getElement, postElement } from '../../../../api/config';
import { getPathParam } from '../../../../utils/converter';
import SlidePanel from '../../../../components/layout/slide-panel';
import Initials from '../../../../components/common/avatar/initials';
import { Warning } from '../../../../components/common/alert/banner';
import { Controller, useForm } from 'react-hook-form';
import InputFormattedNumber from '../../../../components/common/form/input-formatted-number';
import InputDate from '../../../../components/common/form/input-date';
import { Disclosure } from '@headlessui/react';
import InputSubmit from '../../../../components/common/form/input-submit';
import InputShippingLineSelect from '../../../../components/common/form/input-shipping-line-select';
import { fetchShippingLines, fetchTermTypes } from '../../../../reducers/configReducer';
import { useLocation } from 'react-router-dom';
import InputSelect from '../../../../components/common/form/input-select';


const ManageForwarder = ({ bookingDetail, isOpen, setIsOpen, negotiationList, onRefresh, isBooking = true, companyTrafficLine = false }) => {

    const { t } = useTranslation();
    const STEP_1 = 'select_forwarder';
    const STEP_2 = 'configure_forwarder';

    const [selectedForwarders, setSelectedForwarders] = useState([]);
    const [step, setStep] = useState(STEP_1);

    const shippingType = bookingDetail.shipping_type;


    useEffect(() => {
        if (selectedForwarders.length === 0 && step === STEP_2) {
            setStep(STEP_1);
        }

    }, [selectedForwarders, step]);


    return (
        <SlidePanel
            title={t('app.fulfillment.create_booking')}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
            hasDivider={false}
            bg={'bg-white'}
            size='max-w-4xl'
            setClean={() => {
                
            }}
        >
        

            {
                step === STEP_1 && (
                    <>
                        <div className="sm:flex sm:items-center my-3">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-medium text-gray-900">{t("app.fulfillment.nogo_title")}</h1>
                                <p className="mt-2 text-sm text-gray-800">
                                    {t("app.fulfillment.nogo_description", { type: bookingDetail.type })}
                                </p>
                            </div>

                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                {
                                    selectedForwarders.length > 0 && (
                                        <FilterButton
                                            onClick={() => {
                                                setStep(STEP_2);
                                            }}
                                            isRightIcon={true}
                                            icon={
                                                <ArrowRightIcon
                                                    className="w-4 h-4 ml-2"
                                                    aria-hidden="true"
                                                />
                                            }
                                            text={t("app.common.back")}
                                        />
                                    )
                                }
                            </div>
                        </div>

                        


                        {
                            isOpen && (
                                <ForwarderList 
                                    isOpen={isOpen}
                                    shippingType={shippingType} 
                                    negotiationList={negotiationList}
                                    selectedForwarders={selectedForwarders} 
                                    setSelectedForwarders={(r) => { setSelectedForwarders(r); setStep(STEP_2); }}
                                />
                            )
                        }
                    </>
                )
            }

            {
                step === STEP_2 && (
                    <>
                        <div className="my-3">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-medium text-gray-900">{t("app.fulfillment.conf_title")}</h1>
                                <p className="mt-2 text-sm text-gray-800">
                                    {t("app.fulfillment.conf_description", { type: bookingDetail.type })}
                                </p>
                            </div>


                            
                            <div className="mt-4 flex items-center justify-between">
                                <div
                                    className="text-sm underline inline-flex items-center cursor-pointer"
                                    onClick={() => { setStep(STEP_1); }}
                                >
                                    <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                                    {t("app.common.back")}
                                </div>
                            </div>
                         
                        </div>

                        <div className='mb-10'>
                            <ForwarderCard
                                isBooking={isBooking}
                                companyTrafficLine={companyTrafficLine}
                                bookingDetail={bookingDetail}
                                forwarderList={selectedForwarders}
                                setIsOpen={setIsOpen}
                                onRemove={(res) => {
                                    setSelectedForwarders(selectedForwarders.filter(r => r.id !== res.id ))
                                }}
                                onRemoveForwarder={(id) => {
                                    setSelectedForwarders(selectedForwarders.filter(r => r.id !== id))
                                    onRefresh(true)
                                }}
                            />
                        </div>    
                        
                    </>
                )
            }

        </SlidePanel>
    );
};


// STEP 1
const ForwarderList = ({ isOpen, shippingType, selectedForwarders, setSelectedForwarders, negotiationList }) => {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);


    const [sortBy, setSortBy] = useState({ accessor: null, type: null, id: null, position: 0 });
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState(selectedForwarders ?? []);
    const [isLoading, setLoading] = useState(true);

    const [forwarderCount, setForwarderCount] = useState(0);
    const [forwarderList, setForwarderList] = useState([]);
    const [query, setQuery] = useState("");


    const columns = [
        {
            header: t("app.fulfillment.forwarder"),
            id: 'business_name',
            accessor: r => r.business_name,
        },
        {
            header: t("app.fulfillment.primary_contact"),
            id: 'reference',
            accessor: r => r?.primary_contact?.email ?? '',
        },
    ];



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        async function fetchData() {
            if (isOpen && loggedCompany) {
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, FORWARDERS_LINE]), signal, { query: query, shipping_type: shippingType.code, limit: 30 });
                    setForwarderCount(res.total_rows);
                    setForwarderList(res.forwarders);
                } catch (e) {
                    setForwarderList([]);
                    setForwarderCount(0);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, loggedCompany, query]);



    const handleSelectAll = () => {
        if (forwarderList.length > 0) {
            setSelectAll(!selectAll);
            setIsCheck(forwarderList);
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };


    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let forwarderListd = forwarderList.find(r => r.id === id);
        setIsCheck([...isCheck, forwarderListd]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };


    useEffect(() => {
        setForwarderList(forwarderList);
        //eslint-disable-next-line
    }, [negotiationList]);

    

    
    return (

        <>
            <div className='border  border-gray-100 mb-10'>
                <ListSection
                    title={t("app.common.forwarders")}
                    columns={columns}
                    totalRows={forwarderCount}
                    list={forwarderList ?? []}
                    onSearch={setQuery}
                    isLoading={isLoading}
                    mobileRow={MobRow}
                    desktopRow={DeskRow}
                    hasCheckbox={true}
                    isDynamicSearch={true}
                    selectAll={selectAll}
                    handleSelectAll={handleSelectAll}
                    isTableSort={true}
                    sortBy={sortBy}
                    onSortBy={(n) => {
                        setSortBy(n);
                    }}
                    extras={{
                        isCheck,
                        selectedForwarders,
                        handleClick
                    }}
                >

                {
                    isCheck.length > 0 && (
                        <div className="inline-flex items-center justify-center">
                            <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.fulfillment.item_selected")}</p>
                            <ImportButton
                                onClick={() => {
                                    setSelectedForwarders(isCheck)
                                }}
                                icon={
                                    <PlusIcon
                                        className="w-4 h-4 fill-current flex-shrink-0"
                                        aria-hidden="true"
                                    />
                                }
                            />
                        </div>
                    )
                }

                </ListSection>
            </div>
        
        </>

     
    )
}

const MobRow = ({ element, extras }) => {
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { t } = useTranslation();

    return (
        <li key={element.id} className={isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4'}>
            <div className="flex-grow">


                <div className="pb-4 items-center flex justify-between">

                    <div className={"flex justify-between items-center"}>
                        <input type="checkbox"
                            id={element.id}
                            onChange={extras.handleClick}
                            checked={isCheck}
                            className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                       
                        <span className="flex min-w-0 items-center justify-between space-x-1 mt-2 lg:hidden">
                            <Initials
                                color={element?.color ?? ''}
                                name={element?.name ?? ''}
                                isVerified={false}
                            />
                            <span className="flex min-w-0 flex-1 flex-col">
                                <span className="truncate text-sm font-medium text-gray-900">{element?.name ?? ''}</span>
                            </span>
                        </span>

                    </div>
                </div>



                <div className="flex flex-col">


                    <CardDetail title={t("app.fulfillment.primary_contact")}>
                        {
                            element.primary_contact ? (
                                <>
                                    <div className="text-sm text-gray-900">
                                        {element.primary_contact.first_name}{' '}{element.primary_contact.last_name}
                                    </div>
                                    <div className="text-xs flex-wrap text-gray-700">
                                        {element.primary_contact.email}
                                    </div>
                                </>
                            ) : (
                                t("app.container.container_not_defined")
                            )
                        }
                    </CardDetail>
                  


                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { t } = useTranslation();

    return (

        <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>
            <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                {
                    isCheck && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )
                }
                <input type="checkbox"
                    id={element.id}
                    onChange={extras.handleClick}
                    checked={isCheck}
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                />
            </TableDetail>

            <TableDetail extraClass={"whitespace-pre-line"} >
                <span className="flex items-center">
                    <Initials
                        color={element?.color ?? ''}
                        name={element?.name ?? ''}
                        isVerified={false}
                    />
                    <span className="flex min-w-0 flex-1 flex-col">
                        <span className="truncate text-sm font-medium text-gray-900">{element?.name ?? ''}</span>
                    </span>
                </span>
            </TableDetail>

    {
        /**
                 
            <TableDetail>
                        
                            {
                                element.last_price.length > 0 ? (
                                    <NumberFormat
                                        value={element.last_price[0].price ?? 0}
                                        displayType={"text"}
                                        decimalScale={2}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        suffix={" €"}
                                    />
                                ) : (
                                        t("app.fulfillment.no_last_price")
                                )
                            }

                            
                        </TableDetail>

                    */
    }

            <TableDetail >
                {
                    element.primary_contact ? (
                        <>
                            <div className="text-sm text-gray-900">
                                {element.primary_contact.first_name}{' '}{element.primary_contact.last_name}
                            </div>
                            <div className="text-xs flex-wrap text-gray-700">
                                {element.primary_contact.email}
                            </div>
                        </>
                    ) : (
                        t("app.container.container_not_defined")
                    )
                }
            </TableDetail>
        </tr>
    );
};

// STEP 2
const ForwarderCard = ({ bookingDetail, forwarderList, onRemove, setIsOpen, onRemoveForwarder, isBooking, companyTrafficLine }) => {

    return (
        <>
            <div className="mt-6">
                <ul className="grid grid-cols-1 gap-3 sm:grid-cols-1">

                    {
                        forwarderList.map((forwarder) => (
                            <ForwarderDetail
                                isBooking={isBooking}
                                companyTrafficLine={companyTrafficLine}
                                bookingDetail={bookingDetail}
                                key={forwarder.id}
                                forwarder={forwarder}
                                onRemove={() => {
                                    onRemove(forwarder)
                                }}
                                setIsOpen={setIsOpen}
                                onRemoveForwarder={onRemoveForwarder}
                            />
                        ))

                    }

               
                </ul>
            </div>

        </>
    )
}

const ForwarderDetail = ({ bookingDetail, forwarder, onRemove = null, onRemoveForwarder, isBooking, companyTrafficLine }) => {

    const { t } = useTranslation();
    const { company } = useSelector((state) => state.userCompany);
    const { shippingLinesList, isShippingLinesListLoading, termTypesList, isTermTypesListLoading } = useSelector((state) => state.config);

    const dispatch = useDispatch();
    const location = useLocation();


    useEffect(() => {
        dispatch(fetchShippingLines());
        dispatch(fetchTermTypes());
    }, [dispatch]);

    // get Shipping
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const [isAdded, setAdded] = useState(false);
    const { watch, control, handleSubmit, setValue } = useForm({ mode: "onBlur" });


    const onSubmit = async (data) => {
        if (isBooking){
            data['forwarder'] = { id: forwarder.id }
        }else{
            const searchParams = new URLSearchParams(location.search);
            const type = searchParams.get('type');
            data['tl_type'] = type
            data['company_traffic_line'] = { id: companyTrafficLine.id }
        }
        setLoading(true);
        setException(false);
        postElement(isBooking ? getPathParam([BOOKING, bookingDetail.id, TRAFFIC_LINE]) : getPathParam([FORWARDER, forwarder.id, TRAFFIC_LINE]), data)
            .then(response => {
                setAdded(true);
                onRemoveForwarder(forwarder.id)
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };

    return (

        <>
            <li>
                
                <form onSubmit={handleSubmit(onSubmit)} className="col-span-1 border flex flex-col divide-y divide-gray-200 rounded-lg bg-white">
                    <div className='flex items-center justify-between'>
                        <span className="flex items-center py-3 px-6">
                            <Initials
                                color={forwarder?.color ?? ''}
                                name={forwarder?.name ?? ''}
                                isVerified={false}
                            />
                            <span className="flex min-w-0 text-left flex-1 flex-col">
                                <span className="truncate text-sm font-medium whitespace-pre-line text-gray-900">{forwarder?.name ?? ''}</span>
                            </span>
                        </span>
                        {
                            !isAdded && (
                                <span className='cursor-pointer' onClick={onRemove}>
                                    <TrashIcon className='h-5 w-5 text-red-800 hover:text-red-700 mr-3' />
                                </span>
                            )
                        }
                       
                    </div>
                    
                    
                    <div className='p-6'>
                        <div className="  grid md:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-4 sm:grid-cols-1 ">


                            <InputShippingLineSelect
                                isRequired={true}
                                label={t("app.shippings.shipping_line")}
                                placeholder={t("app.shippings.shipping_line")}
                                disabled={isAdded}
                                isLoadingData={isShippingLinesListLoading}
                                data={(watch('shipping_line') && !shippingLinesList.some(vessel => vessel.id === watch('shipping_line').id)) ? [...shippingLinesList, watch('shipping_line')] : shippingLinesList}
                                selectedItem={watch('shipping_line')}
                                onSelect={(e) => {
                                    setValue('shipping_line', e)
                                }}
                            />


                            <Controller
                                name="departure_date"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputDate
                                        label={t("app.shippings.departure_date")}
                                        format={"YYYY-MM-D HH:mm:ss"}
                                        placeholder={"Es. 01/12/2023"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={false}
                                        isShowTime={false}
                                        isRequired={false}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />


                            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
                                <Controller
                                    name="start_date"
                                    rules={{ required: false }}
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                        fieldState: { error },
                                    }) => (
                                        <InputDate
                                            label={t("app.booking.start_date")}
                                            format={"YYYY-MM-D HH:mm:ss"}
                                            placeholder={"Es. 01/12/2023"}
                                            activeMinDate={false}
                                            startDate={value}
                                            disabled={isAdded}
                                            isShowTime={false}
                                            isRequired={false}
                                            errors={error}
                                            name={name}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="end_date"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                        fieldState: { error },
                                    }) => (
                                        <InputDate
                                            label={t("app.booking.end_date")}
                                            format={"YYYY-MM-D HH:mm:ss"}
                                            placeholder={"Es. 01/12/2023"}
                                            activeMinDate={false}
                                            startDate={value}
                                            disabled={isAdded}
                                            isShowTime={false}
                                            isRequired={true}
                                            errors={error}
                                            name={name}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            

                            <Controller
                                name="transit_time"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.transit_time")}
                                        isShowLabel={true}
                                        isRequired={true}
                                        isDisabled={isAdded}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={' ' + (watch("transit_time") > 1 ? t("app.plan.days") : t("app.plan.day"))}
                                        errors={error}
                                    />
                                )}
                            />

                            <Controller
                                name="free_time"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.free_time")}
                                        isShowLabel={true}
                                        isDisabled={isAdded}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isRequired={true}
                                        prefix={''}
                                        suffix={' ' + (watch("free_time") > 1 ? t("app.plan.days") : t("app.plan.day"))}
                                        errors={error}
                                    />
                                )}
                            />





                            <Controller
                                name="transport_cost"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.transport_cost")}
                                        isShowLabel={true}
                                        isRequired={true}
                                        name={name}
                                        isDisabled={isAdded}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        errors={error}
                                    />
                                )}
                            />


                            {
                                companyTrafficLine && (
                                    <Controller
                                        name="terms_type"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputSelect
                                                label={t("app.shippings.terms_type")}
                                                name={name}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                options={termTypesList}
                                                value={value}
                                                isLoading={isTermTypesListLoading}
                                                isDisabled={isTermTypesListLoading}
                                                errors={error}
                                            />
                                        )}
                                    />
                                )
                            }

                        </div>


                        <Disclosure>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className=" my-6 flex w-full justify-end rounded-lg  items-center  text-left text-sm font-medium text-gray-900 hover:text-gray-600">
                                        <span>{t("app.booking.more_information")}</span>
                                        <PlusIcon
                                            className={"h-4 w-4 ml-2"}
                                        />
                                    </Disclosure.Button>



                                    <Disclosure.Panel className=" grid md:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-4 sm:grid-cols-1">

                                        <Controller
                                            name="extra_stops"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                <InputFormattedNumber
                                                    label={t("app.booking.extra_stops")}
                                                    isShowLabel={true}
                                                    isRequired={false}
                                                    isDisabled={isAdded}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    prefix={''}
                                                    suffix={""}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="extra_stops_cost"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                <InputFormattedNumber
                                                    label={t("app.booking.extra_stops_cost")}
                                                    isShowLabel={true}
                                                    isRequired={false}
                                                    isDisabled={isAdded}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    prefix={''}
                                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    errors={error}
                                                />
                                            )}
                                        />


                                        <Controller
                                            name="service_accessory_cost"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                <InputFormattedNumber
                                                    label={t("app.booking.service_accessory_cost")}
                                                    isShowLabel={true}
                                                    isRequired={false}
                                                    isDisabled={isAdded}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    prefix={''}
                                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    errors={error}
                                                />
                                            )}
                                        />


                                        <Controller
                                            name="administrative_cost"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                <InputFormattedNumber
                                                    label={t("app.booking.administrative_cost")}
                                                    isShowLabel={true}
                                                    isRequired={false}
                                                    isDisabled={isAdded}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    prefix={''}
                                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="delivery_cost"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                <InputFormattedNumber
                                                    label={t("app.booking.delivery_cost")}
                                                    isShowLabel={true}
                                                    isRequired={false}
                                                    isDisabled={isAdded}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    prefix={''}
                                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    errors={error}
                                                />
                                            )}
                                        />


                                        <Controller
                                            name="insurance_cost"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                <InputFormattedNumber
                                                    label={t("app.booking.insurance_cost")}
                                                    isShowLabel={true}
                                                    isRequired={false}
                                                    isDisabled={isAdded}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    prefix={''}
                                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    errors={error}
                                                />
                                            )}
                                        />



                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </div>

                    
                    {/* Warning*/}
                    {exception && (
                        <Warning message={exception} />
                    )}


                    {
                        !isAdded && (
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200 ">
                                    <div className="flex items-center justify-center p-2   w-0 flex-1">
                                        <InputSubmit isLoading={isLoading} label={t("app.booking.add_nogotation")} />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                


                 
                </form>
            </li>
        </>
    )
}



export default ManageForwarder;
