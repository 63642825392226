import React, {useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EncodingSlidePanel from "../../../../components/partials/panel/encding-slide-panel";
import LoaderWrapper from "../../../../components/common/loader/loader-wrapper";
import RemovePopup from "../../../../components/common/popup/remove-popup";
import { ChevronRightIcon, DuplicateIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Warning } from "postcss";
import InputSubmit from "../../../../components/common/form/input-submit";
import InputFormattedNumber from "../../../../components/common/form/input-formatted-number";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import { getElement, postElement, putElement } from "../../../../api/config";
import { CLONE, COMPANY_ATTRIBUTE, COMPANY_ATTRIBUTE_CATEGORIES, COMPANY_ATTRIBUTES, COMPANY_SUB_ATTRIBUTES, PRODUCT_ENCCODE, PRODUCT_ENCODE_ATTRIBUTE, PRODUCT_ENCODE_ATTRIBUTES } from "../../../../api/endpoints";
import { getPathParam, getSelectParams, makeid } from "../../../../utils/converter";
import toast from "react-hot-toast";
import InputSelect from "../../../../components/common/form/input-select";
import { COMPANY } from "../../../endpoints";
import FormPopup from "../../../../components/common/popup/form-popup";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function formatNumberWithLeadingZeros(number, start_from) {
    let startString = start_from.toString();
    let zerosToAdd = number - startString.length;
    let result = zerosToAdd > 0 ? '0'.repeat(zerosToAdd) + startString : startString;
    return result;
}



const ManageEncoding = ({ isOpenPanel, setOpenPanel, encodingData }) => {

    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();

    // Remove Details
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});


    // Encode Manage
    const [isLoadingConfiguration, setLoadingConfiguration] = useState(true);
    const [configurationColumn, setConfigurationColumn] = useState([{ type: 'primary', position: 1, childId: '', configuration: [], isLoading: false }]);

    // Attribute
    const [isLoadingAttributes, setLoadingAttributes] = useState(true);
    const [attributes, setAttributes] = useState([]);

    // Sub Attribute
    const [isLoadingSubAttributes, setLoadingSubAttributes] = useState(true);
    const [subAttributes, setSubAttributes] = useState([]);


    // Attribute
    const [isLoadingAttributeGroups, setLoadingAttributeGroups] = useState(true);
    const [attributeGroups, setAttributeGroups] = useState([]);


    // Add
    const [isAddCombination, setAddCombination] = useState(false);
    const [attibuteParent, setAttibuteParent] = useState(false);


    // Edit Details
    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editDetail, setEditDetail] = useState({});
    const [editPosition, setEditPosition] = useState(false);

    // Clone Details
    const [isOpenClone, setOpenClone] = useState(false);
    const [cloneDetail, setCloneDetail] = useState({});


    const lastGroup = useRef(null);

    const scrollTo = () => {
        lastGroup.current.scrollIntoView({ behavior: "smooth", left: lastGroup.current.scrollWidth })
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (isOpenPanel && loggedCompany) {
                setLoadingConfiguration(true);
                try {
                    const res = await getElement(getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTES]), signal);
                    let filtered = [...configurationColumn];
                    let foundIndex = filtered.findIndex((x) => x.type === 'primary');
                    filtered[foundIndex]['configuration'] = res.product_encode_attributes;
                    setConfigurationColumn(filtered)
                } catch (e) {
                    setLoadingConfiguration(false);
                } finally {
                    setLoadingConfiguration(false);
                }
            }
        }


        async function fetchAttributeCategory() {
            if (isOpenPanel && loggedCompany) {
                setLoadingAttributeGroups(true);
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTE_CATEGORIES]), signal);
                    setAttributeGroups(getSelectParams(res.categories, "name"));

                } catch (e) {
                    setLoadingAttributeGroups(false);
                } finally {
                    setLoadingAttributeGroups(false);
                }
            }
        }

        fetchAttributeCategory();
        fetchData();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, isOpenPanel]);


    const fetchAttribute = async (category_id = null) => {
        if (isOpenPanel && loggedCompany) {
            setLoadingAttributes(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTES]), null, { category: category_id });
                setAttributes(res.attributes.length === 0 ?  [] : getSelectParams(res.attributes, "name"));
                setLoadingAttributes(false);
            } catch (e) {
                setLoadingAttributes(false);
            } finally {
                setLoadingAttributes(false);
            }
        }
    }


    const fetchSubAttribute = async (attribute_id) => {
        if (loggedCompany) {
            setLoadingSubAttributes(true);
            try {
                const res = await getElement(getPathParam([COMPANY_ATTRIBUTE, attribute_id, COMPANY_SUB_ATTRIBUTES]));
                setSubAttributes(getSelectParams(res.sub_attributes, "name"));
                setLoadingSubAttributes(false);
            } catch (e) {
                setSubAttributes([]);
            } finally {
                setLoadingSubAttributes(false);
            }
        }
    }

    const fetchSubConfiguration = async (position, encode) => {
        if (loggedCompany) {
            try {


                let dynamicId = makeid(10);
                let filtered = [...configurationColumn];
                filtered = filtered.filter(r => position >= r.position);
                let nuovaPosizione = position + 1;
                filtered.push({
                    type: 'child',
                    dynamicId: dynamicId,
                    position: nuovaPosizione,
                    childId: '',
                    cardType: '',
                    titleAttribute: '',
                    titleSubAttribute: '',
                    configuration: '',
                    isLoading: true
                });
                setConfigurationColumn(filtered);


                setTimeout(async function () {
                    const res = await getElement(getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTE, encode.id]));
                    let filtered2 = [...filtered];
                    let foundIndex = filtered2.findIndex((x) => x.dynamicId === dynamicId);
                    filtered[foundIndex]['childId'] = res.id;
                    filtered[foundIndex]['cardType'] = res.type;
                    filtered[foundIndex]['titleAttribute'] = res?.children_title ?? t("app.catalog.sub_attributes");
                    filtered[foundIndex]['titleSubAttribute'] = res?.company_sub_attribute?.name ?? '';
                    filtered[foundIndex]['configuration'] = res.product_encode_attributes;
                    filtered[foundIndex]['isLoading'] = false;
                    setConfigurationColumn(filtered2);
                    scrollTo();
                }, 50);



                } catch (e) {
                //console.log(e);
            }
        }
    }


    const generateProductReference = () => {
        let GeneretedCode = encodingData.code;
        if (configurationColumn && configurationColumn.length > 1) {
            // eslint-disable-next-line
            configurationColumn.map((configuration) => {

                if (configuration.childId && configuration.childId !== '') {
                    const confResult = configurationColumn.find(r =>
                        r.configuration.some(n => n.id === configuration.childId)
                    )?.configuration.find(n => n.id === configuration.childId);

                    if (confResult) {
                        if (confResult.type === 'incrementer') {
                            GeneretedCode = GeneretedCode + formatNumberWithLeadingZeros(confResult.code, confResult.start_from)
                        } else {
                            GeneretedCode = GeneretedCode + confResult.code
                        }
                    }

                }
            })
        }
        //encodingData.number_limit
        return GeneretedCode;
    }



    return (
        <EncodingSlidePanel
            isOpen={isOpenPanel}
            setIsOpen={setOpenPanel}
            setClean={() => {
                setLoadingConfiguration(true);
                setConfigurationColumn([{ type: 'primary', position: 1, childId: '', configuration: [] }]);
                setLoadingAttributes(true);
                setAttributes([]);
                setLoadingSubAttributes(true);
                setSubAttributes([]);

                // TODO
                setAddCombination(false);
                setAttibuteParent(false);
            }}
            encodeLimit={encodingData.number_limit}
            codeHeader={generateProductReference()}
            title={encodingData?.title ?? ''}

        >
            <LoaderWrapper isLoading={isLoadingConfiguration}>

                <div class={"grow relative mt-3 h-[100vh]"}>
                    <div className="transition ease-in-out delay-150 overflow-x-auto overflow-y-auto h-[90%] absolute select-none whitespace-nowrap mb-2 pt-0.5 pb-2 -top-0.5 bottom-0 inset-x-0 flex flex-row px-1.5 py-0">


                        {
                            configurationColumn.map((configuration, index) => (
                                <>
                                    {configuration.type === 'primary' && (
                                        <PrimaryCombinationColumn
                                            key={index}
                                            encodingData={encodingData}
                                            position={configuration.position}
                                            isActiveCard={configurationColumn?.find(r => r.position === (configuration.position + 1))?.childId ?? false}
                                            primaryConfiguration={configuration.configuration}
                                            onAddCombination={() => {
                                                setAttibuteParent(false);
                                                setAddCombination(true);
                                            }}
                                            onOpenChildren={async (position, r) => {
                                                await fetchSubConfiguration(position, r);
                                            }}
                                            onRemove={(detail) => {
                                                setRemoveDetail(detail);
                                                setOpenRemove(true);
                                            }}
                                            onEdit={(detail) => {
                                                setEditDetail(detail);
                                                setEditPosition(configuration.position);
                                                setOpenEdit(true);
                                            }}
                                            onClone={(detail) => {
                                                setAttibuteParent(false);
                                                setCloneDetail(detail);
                                                setOpenClone(true);
                                            }}

                                        />
                                    )}

                                    {configuration.type === 'child' && (
                                        <CombinationColumn
                                            key={index}
                                            title={configuration.cardType === 'incrementer' ? t("app.catalog.incremental") : configuration.titleAttribute}
                                            encodingData={encodingData}
                                            isLoading={configuration.isLoading}
                                            childId={configuration.childId}
                                            isActiveCard={configurationColumn?.find(r => r.position === (configuration.position + 1))?.childId ?? false}
                                            position={configuration.position}
                                            configurations={configuration.configuration}
                                            onAddCombination={(r) => {
                                                setAttibuteParent(r);
                                                setAddCombination(true);
                                            }}
                                            onOpenChildren={async (position, r) => {
                                                await fetchSubConfiguration(position, r);
                                            }}
                                            onRemove={(detail) => {
                                                setRemoveDetail(detail);
                                                setOpenRemove(true);
                                            }}
                                            onEdit={(detail) => {
                                                setEditDetail(detail);
                                                setEditPosition(configuration.position);
                                                setOpenEdit(true);
                                            }}
                                            onClone={(r, detail) => {
                                                setAttibuteParent(r);
                                                setCloneDetail(detail);
                                                setOpenClone(true);
                                            }}
                                        />
                                    )}
                                </>

                            ))
                        }




                        <div ref={lastGroup} />
                    </div>
                </div>


                {
                    isAddCombination && (
                        <CombinationAdd
                            isOpen={isAddCombination}
                            setIsOpen={setAddCombination}
                            primaryConfiguration={configurationColumn?.find(r => r.type === 'primary')?.configuration ?? []}
                            encodeData={encodingData}
                            onAddedConfiguration={(r) => {
                                let filtered = [...configurationColumn];
                                let foundIndex = filtered.findIndex((x) => x.type === 'primary');
                                filtered[foundIndex]['configuration'] = r;
                                setConfigurationColumn(filtered)

                            }}
                            attributes={attributes}
                            isLoadingAttributes={isLoadingAttributes}
                            onFetchAttribute={(r) => {
                                fetchAttribute(r);
                            }}
                            isLoadingSubAttributes={isLoadingSubAttributes}
                            subAttributes={subAttributes}
                            onFetchSubAttribute={(r) => {
                                fetchSubAttribute(r);
                            }}
                            isLoadingAttributeGroups={isLoadingAttributeGroups}
                            attributeGroups={attributeGroups}
                            attibuteParent={attibuteParent}
                            onAddChildConfiguration={(id, r) => {
                                let filtered = [...configurationColumn];
                                let foundIndex = filtered.findIndex((x) => x.type === 'child' && x.childId === id);
                                filtered[foundIndex]['configuration'] = r;
                                setConfigurationColumn(filtered);
                                setAttibuteParent(false);
                            }}
                        />
                    )
                }


                {
                    isOpenClone && (
                        <CombinationClone
                            isOpen={isOpenClone}
                            setIsOpen={setOpenClone}
                            cloneDetail={cloneDetail}
                            encodeData={encodingData}
                            attributes={attributes}
                            isLoadingAttributes={isLoadingAttributes}
                            onFetchSubAttribute={(r) => {
                                fetchSubAttribute(r);
                            }}
                            isLoadingSubAttributes={isLoadingSubAttributes}
                            subAttributes={subAttributes}
                            onFetchAttribute={(r) => {
                                fetchAttribute(r);
                            }}
                            isLoadingAttributeGroups={isLoadingAttributeGroups}
                            attributeGroups={attributeGroups}
                            onAddedConfiguration={(r) => {
                                let filtered = [...configurationColumn];
                                let foundIndex = filtered.findIndex((x) => x.type === 'primary');
                                filtered[foundIndex]['configuration'] = r;
                                setConfigurationColumn(filtered)

                            }}
                            attibuteParent={attibuteParent}
                            onAddChildConfiguration={(id, r) => {
                                let filtered = [...configurationColumn];
                                let foundIndex = filtered.findIndex((x) => x.type === 'child' && x.childId === id);
                                filtered[foundIndex]['configuration'] = r;
                                console.log(filtered[foundIndex]['configuration']);

                                setConfigurationColumn(filtered);
                                setAttibuteParent(false);
                            }}
                        />
                    )
                }

                {isOpenRemove && (
                    <RemovePopup
                        isOpen={isOpenRemove}
                        setIsOpen={setOpenRemove}
                        detail={removeDetail}
                        setDetail={(res) => {
                            let filtered = [...configurationColumn];
                            filtered = filtered.filter(r => removeDetail.position >= r.position);
                            let foundIndex = filtered.findIndex((x) => x.position === removeDetail.position);
                            filtered[foundIndex]['configuration'] = res.product_encode_attributes;
                            setConfigurationColumn(filtered);
                        }}
                    />
                )}


                {isOpenEdit && (
                    <CombinationEdit
                        encodeData={encodingData}
                        isOpen={isOpenEdit}
                        setIsOpen={setOpenEdit}
                        editDetail={editDetail}
                        onUpdateData={(res) => {
                            let filtered = [...configurationColumn];
                            let foundIndex = filtered.findIndex((x) => x.position === editPosition);
                            filtered[foundIndex]['configuration'] = res.product_encode_attributes;
                            setConfigurationColumn(filtered);
                        }}
                    />
                )}
            </LoaderWrapper>


        </EncodingSlidePanel>
    );
};



// to finish
const PrimaryCombinationColumn = ({ encodingData, primaryConfiguration, onAddCombination, onOpenChildren, position, onRemove, isActiveCard, onEdit, onClone }) => {

    const { t } = useTranslation();

    return (
        <div className={`self-start shrink-0 block  h-full  whitespace-nowrap px-1.5 py-0`} >
            <div className="bg-solitude-400  rounded-lg shadow-md  flex justify-between  h-full flex-grow overflow-y-scroll flex-col  shrink-0 basis-[300px] relative whitespace-normal w-[300px] box-border align-top pb-2 scroll-m-2">

                <div className={`gap-y-0  relative  flex-grow  items-start flex-wrap mx-1  py-1 px-2  `} >
                    <ColomnHeader title={t("app.catalog.primary_combination")} onClick={() => { onAddCombination() }} />
                    {
                        (primaryConfiguration?.length ?? []) > 0 && (
                            primaryConfiguration.map((encode, index) => (
                                <div>

                                    {
                                        encode.company_sub_attribute && (
                                            <CombinationCard
                                                type={encode.type}
                                                isActiveCard={isActiveCard === encode.id}
                                                onClick={() => { onOpenChildren(position, encode) }}
                                                name={encode.company_sub_attribute.name}
                                                code={encode.code}
                                                description={encode.description}
                                                onRemove={() => {
                                                    const removeDetail = {
                                                        title: t("app.common.remove_generic_title"),
                                                        position: position,
                                                        message: t("app.common.remove_generic_description"),
                                                        endpoint: getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTE, encode.id]),
                                                    };
                                                    onRemove(removeDetail)
                                                }}
                                                onEdit={(r) => {
                                                    onEdit(encode);
                                                }}
                                                onClone={() => {
                                                    onClone(encode);
                                                }}
                                            />
                                        )
                                    }

                                    {
                                        encode.type === 'incrementer' && (
                                            <CombinationCard
                                                type={encode.type}
                                                isActiveCard={isActiveCard === encode.id}
                                                onClick={() => { onOpenChildren(position, encode) }}
                                                name={encode.code}
                                                code={encode.start_from}
                                                onRemove={() => {
                                                    const removeDetail = {
                                                        title: t("app.common.remove_generic_title"),
                                                        position: position,
                                                        message: t("app.common.remove_generic_description"),
                                                        endpoint: getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTE, encode.id]),
                                                    };
                                                    onRemove(removeDetail)
                                                }}
                                                onEdit={(r) => {
                                                    onEdit(encode);
                                                }}
                                                onClone={() => {
                                                    onClone(encode);
                                                }}
                                            />
                                        )
                                    }

                                </div>
                            ))
                        )
                    }

                </div>


            </div>
        </div>
    )

}

// ADD Data
const CombinationAdd = ({ isOpen, setIsOpen, primaryConfiguration, encodeData, onAddedConfiguration, attributes, isLoadingAttributes, onFetchSubAttribute, subAttributes, isLoadingSubAttributes, attibuteParent, onAddChildConfiguration, onFetchAttribute, isLoadingAttributeGroups, attributeGroups }) => {

    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
        register
    } = useForm({ mode: "onBlur" });


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const [dataAttributes, setDataAttributes] = useState([]);


    // TEMPLATE CATEGORY
    const getEcodingData = () => [
        {
            value: 'attribute',
            type: 'general',
            label: t('app.catalog.attribute_s')
        },
        !primaryConfiguration.find(r => r.type === 'incrementer') && {
            value: 'incrementer',
            type: 'incrementer',
            label: t('app.catalog.incremental')
        }
    ].filter(Boolean);


    const storeCombination = async (data) => {
        setLoading(true);
        postElement(getPathParam([PRODUCT_ENCCODE, encodeData.id, PRODUCT_ENCODE_ATTRIBUTE]), data)
            .then(response => {
                toast.success(t("app.tasks.update_success"));
                if (attibuteParent){
                    onAddChildConfiguration(attibuteParent, response.product_encode_attributes);
                }else{
                    onAddedConfiguration(response.product_encode_attributes);
                }

                setLoading(false);
                setIsOpen(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };


    const onSubmit = async (data) => {


        let response = { type: data['types'].type };
        if (attibuteParent) {
            response['product_encode_attribute'] = { id: attibuteParent }
        }


        if (data['types'].value === "attribute") {
            response['encode_items'] = dataAttributes
        }

        if (data['types'].value === "incrementer") {
            response['encode_items'] = [{ code: data['code'], start_from: data['start_from'] }]
        }

        await storeCombination(response);
    };



    useEffect(() => {
        if (watch('code')) {
            setValue('number', watch('code').length);
        }
        // eslint-disable-next-line
    }, [watch('code')]);


    useEffect(() => {
        setValue('company_attribute', null)
        setValue('company_sub_attribute', null)
        setValue('code', null)
        setValue('number', null)
    }, [setValue, attributes]);

    return (
        <FormPopup
            title={t("app.fulfillment.add_configuration")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-2">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                        <div className="col-span-2">
                            <Controller
                                name="types"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputSelect
                                        label={t("app.catalog.types")}
                                        name={name}
                                        options={getEcodingData()}
                                        value={value}
                                        isRequired={true}
                                        onChange={(e) => {
                                            setValue('company_attribute_group', null);
                                            setValue('company_attribute', null);
                                            setValue('company_sub_attribute', null);
                                            setValue('start_from', null);
                                            setValue('code', null);
                                            setValue('number', 0);
                                            setDataAttributes([]);
                                            onChange(e);
                                        }}
                                        isLoading={false}
                                        isDisabled={false}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>

                        {
                            watch("types") && watch("types").value === 'attribute' && (
                                <div className="grid col-span-2 md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1 border border-dashed rounded-md p-2">

                                    <div className="col-span-2">
                                        <Controller
                                            name="company_attribute_group"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({
                                                field: { onChange, value, name },
                                                fieldState: { error },
                                            }) => (
                                                <InputSelect
                                                    label={'Tag'}
                                                    name={name}
                                                    options={[
                                                        ...attributeGroups,
                                                        {
                                                            id: 'NON',
                                                            value: 'NON',
                                                            label: t("app.configuration.no_category")
                                                        }
                                                    ]}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        onFetchAttribute(e.id);
                                                    }}
                                                    isLoading={isLoadingAttributeGroups}
                                                    isDisabled={isLoadingAttributeGroups}
                                                    errors={error}
                                                    isMulti={false}
                                                    isRequired={true}
                                                />
                                            )}
                                        />
                                    </div>

                                    <Controller
                                        name="company_attribute"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputSelect
                                                label={t("app.catalog.attribute")}
                                                name={name}
                                                options={attributes}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onFetchSubAttribute(e.id);
                                                }}
                                                isLoading={isLoadingAttributes}
                                                isDisabled={isLoadingAttributes}
                                                errors={error}
                                                isMulti={false}
                                                isRequired={true}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="company_sub_attribute"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputSelect
                                                label={t("app.catalog.sub_attributes")}
                                                name={name}
                                                options={subAttributes}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    setValue('code', e.code)
                                                    setValue('number', e.code.length)
                                                }}
                                                isLoading={isLoadingSubAttributes}
                                                isDisabled={isLoadingSubAttributes || subAttributes.length === 0}
                                                errors={error}
                                                isMulti={false}
                                            />
                                        )}
                                    />

                                    <InputText
                                        id={"code"}
                                        type={"text"}
                                        errors={errors.code}
                                        input={{
                                            ...register("code", { required: false }),
                                        }}
                                        label={t("app.catalog.code")}
                                        isRequired={true}
                                    />

                                    <Controller
                                        name="number"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.catalog.generetor_charaters")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={true}
                                                errors={error}
                                            />
                                        )}
                                    />


                                    <div className="col-span-2 flex  items-center justify-end ">
                                        <div
                                            onClick={() => {
                                                if (dataAttributes.length === 0 || !dataAttributes.find(r => r.code === watch('code') && r.company_attribute.id === watch('company_attribute').id && r.company_sub_attribute.id === watch('company_sub_attribute').id)){
                                                    setDataAttributes([...dataAttributes, { code: watch('code'), name: watch('company_sub_attribute').label, company_attribute: { id: watch('company_attribute').id }, company_sub_attribute: { id: watch('company_sub_attribute').id } }])
                                                }
                                                setValue('company_attribute', null)
                                                setValue('company_sub_attribute', null)
                                                setValue('code', null)
                                                setValue('number', null)
                                            }}
                                            className={`flex  items-center cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-green-800 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
                                            <PlusIcon className="h-4 w-4 mr-2" /> {t("app.common.add")}
                                        </div>
                                    </div>

                                </div>
                            )
                        }

                        {
                            watch("types") && watch("types").value === 'attribute' && dataAttributes.length > 0 && (
                                <div className="grid col-span-2 ">
                                    {
                                        dataAttributes.map((data, index) => (
                                            <div className={classNames('bg-white mt-3 mx-auto w-full border rounded-md group cursor-pointer')}>
                                                <dl className={'flex items-center justify-between'}>
                                                    <div className={"flex flex-col p-3 cursor-pointer"} >
                                                        <div className={"flex items-center text-left"}>
                                                            <div>
                                                                <div className="text-sm text-gray-900">
                                                                    <span>
                                                                        {data.name}
                                                                    </span>
                                                                </div>
                                                                <div className="text-xs text-gray-500" style={{ fontSize: '0.70rem' }}>
                                                                    {t("app.catalog.name")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={"text-right mr-3 "}>
                                                        <div className="block text-right mr-3 flex items-center group-hover:hidden">
                                                            <div>
                                                                <div className="text-sm text-gray-900">
                                                                    <span>
                                                                        {data.code}
                                                                    </span>
                                                                </div>
                                                                <div className="text-xs text-gray-500" style={{ fontSize: '0.70rem' }}>
                                                                    {t("app.catalog.code")}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="hidden group-hover:block group-hover:cursor-pointer group-hover:flex group-hover:items-center">
                                                            <button
                                                                type="button"
                                                                className="text-red-700 hover:text-red-900  ml-2"
                                                                onClick={(e) => {
                                                                    setDataAttributes(dataAttributes.filter(r => (r.code !== data.code && r.company_sub_attribute.id !== data.company_sub_attribute.id)))
                                                                }}
                                                            >
                                                                <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </dl>
                                            </div>

                                        ))
                                    }

                                </div>
                            )
                        }







                        {
                            watch("types") && watch("types").type === 'incrementer' && (
                                <>

                                    <Controller
                                        name="code"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.company_preference.sequential_number")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={false}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="start_from"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.company_preference.next_sequence")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={false}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </>
                            )
                        }

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}


                {
                    (dataAttributes.length > 0 || (watch("types") && watch("types").type === 'incrementer')) && (
                        <div className="flex before:flex-1 items-center justify-between mt-6">
                            <InputSubmit
                                isLoading={isLoading}
                                label={t("app.common.confirm")}
                            />
                        </div>
                    )
                }


            </form>


        </FormPopup>
    )

}


const CombinationClone = ({ isOpen, setIsOpen, cloneDetail, encodeData, onAddedConfiguration, attributes, isLoadingAttributes, onFetchSubAttribute, subAttributes, isLoadingSubAttributes, attibuteParent, onAddChildConfiguration, onFetchAttribute, isLoadingAttributeGroups, attributeGroups }) => {

    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
        register
    } = useForm({ mode: "onBlur" });



    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);


    const storeCombination = async (data) => {
        setLoading(true);
        putElement(getPathParam([PRODUCT_ENCCODE, encodeData.id, PRODUCT_ENCODE_ATTRIBUTE, cloneDetail.id, CLONE]), data)
            .then(response => {
                toast.success(t("app.tasks.update_success"));
                if (attibuteParent) {
                    onAddChildConfiguration(attibuteParent, response.product_encode_attributes);
                } else {
                    onAddedConfiguration(response.product_encode_attributes);
                }
                //dispatch(messageBusActivator(!messageBusNotification));


                setLoading(false);
                setIsOpen(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };


    const onSubmit = async (data) => {

        let response = {code: data['code'] };

        if (cloneDetail.type === "general") {
            response['company_attribute'] = { id: data['company_attribute'].id }
            response['company_sub_attribute'] = { id: data['company_sub_attribute'].id }
            response['description'] = data['description']
            response['children_title'] = data['children_title']
        }

        if (cloneDetail.type === "incrementer") {
            response['start_from'] = data['start_from'];
        }

        await storeCombination(response);
    };

    useEffect(() => {
        if (watch('code')) {
            setValue('number', watch('code').length);
        }
        // eslint-disable-next-line
    }, [watch('code')]);


    useEffect(() => {
        setValue('company_attribute', null)
        setValue('company_sub_attribute', null)
        setValue('code', null)
        setValue('number', null)
        setValue('description', null)
        setValue('children_title', null)
    }, [setValue, attributes]);


    return (
        <FormPopup
            title={t("app.catalog.clone_configuration")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-2">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                        {
                            cloneDetail.type === 'general' && (
                                <>

                                    <div className="col-span-2">
                                        <Controller
                                            name="company_attribute_group"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({
                                                field: { onChange, value, name },
                                                fieldState: { error },
                                            }) => (
                                                <InputSelect
                                                    label={'Tag'}
                                                    name={name}
                                                    options={[
                                                        ...attributeGroups,
                                                        {
                                                            id: 'NON',
                                                            value: 'NON',
                                                            label: t("app.configuration.no_category")
                                                        }
                                                    ]}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        onFetchAttribute(e.id);
                                                    }}
                                                    isLoading={isLoadingAttributeGroups}
                                                    isDisabled={isLoadingAttributeGroups}
                                                    errors={error}
                                                    isMulti={false}
                                                    isRequired={true}
                                                />
                                            )}
                                        />
                                    </div>



                                    <Controller
                                        name="company_attribute"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputSelect
                                                label={t("app.catalog.attribute")}
                                                name={name}
                                                options={attributes}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onFetchSubAttribute(e.id);
                                                }}
                                                isLoading={isLoadingAttributes}
                                                isDisabled={isLoadingAttributes}
                                                errors={error}
                                                isMulti={false}
                                                isRequired={true}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="company_sub_attribute"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputSelect
                                                label={t("app.catalog.sub_attributes")}
                                                name={name}
                                                options={subAttributes}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    setValue('code', e.code)
                                                    setValue('number', e.code.length)
                                                }}
                                                isLoading={isLoadingSubAttributes}
                                                isDisabled={isLoadingSubAttributes || subAttributes.length === 0}
                                                errors={error}
                                                isMulti={false}
                                            />
                                        )}
                                    />




                                </>
                            )
                        }

                        {
                            cloneDetail.type === 'general' && (
                                <>
                                    <InputText
                                        id={"code"}
                                        type={"text"}
                                        errors={errors.code}
                                        input={{
                                            ...register("code", { required: false }),
                                        }}
                                        label={t("app.catalog.code")}
                                        isRequired={true}
                                    />

                                    <Controller
                                        name="number"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.catalog.generetor_charaters")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={true}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <InputText
                                        id={"description"}
                                        type={"text"}
                                        errors={errors.description}
                                        input={{
                                            ...register("description", { required: false }),
                                        }}
                                        label={t("app.catalog.description")}
                                        isRequired={false}
                                    />

                                    <InputText
                                        id={"children_title"}
                                        type={"text"}
                                        errors={errors.children_title}
                                        input={{
                                            ...register("children_title", { required: false }),
                                        }}
                                        label={t("app.catalog.children_title")}
                                        isRequired={false}
                                    />
                                </>
                            )
                        }



                        {
                            cloneDetail.type === 'incrementer' && (
                                <>

                                    <Controller
                                        name="code"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.company_preference.sequential_number")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={false}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="start_from"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.company_preference.next_sequence")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={false}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </>
                            )
                        }

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.common.clone")}
                    />
                </div>
            </form>


        </FormPopup>
    )

}


const CombinationColumn = ({ isLoading, title, encodingData, configurations, onAddCombination, onOpenChildren, childId, position, onRemove, isActiveCard, onEdit, onClone }) => {

    const { t } = useTranslation();


    return (
        <div className={` self-start shrink-0 block  h-full  whitespace-nowrap px-1.5 py-0`} >
            <div className="bg-solitude-400  rounded-lg shadow-md  flex justify-between  h-full flex-grow overflow-y-scroll flex-col  shrink-0 basis-[300px] relative whitespace-normal w-[300px] box-border align-top pb-2 scroll-m-2">

                <div className={`gap-y-0  relative  flex-grow  items-start flex-wrap mx-1  py-1 px-2  `} >


                    <LoaderWrapper isLoading={isLoading}>

                        <ColomnHeader title={title} onClick={() => { onAddCombination(childId) }} />
                        {
                            (configurations?.length ?? []) > 0 && (
                                <>
                                    {configurations.map((encode, index) => (
                                        <div key={index}>

                                            {
                                                encode.company_sub_attribute && (
                                                    <CombinationCard
                                                        type={encode.type}
                                                        onClick={() => { onOpenChildren(position, encode) }}
                                                        name={encode.company_sub_attribute.name}
                                                        isActiveCard={isActiveCard === encode.id}
                                                        code={encode.code}
                                                        description={encode.description}
                                                        onRemove={() => {
                                                            const removeDetail = {
                                                                title: t("app.common.remove_generic_title"),
                                                                position: position,
                                                                message: t("app.common.remove_generic_description"),
                                                                endpoint: getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTE, encode.id]),
                                                            };
                                                            onRemove(removeDetail)
                                                        }}
                                                        onEdit={(r) => {
                                                            onEdit(encode);
                                                        }}
                                                        onClone={() => {
                                                            onClone(childId, encode);
                                                        }}
                                                    />
                                                )
                                            }

                                            {
                                                encode.type === 'incrementer' && (
                                                    <CombinationCard
                                                        type={encode.type}
                                                        onClick={() => { onOpenChildren(position, encode) }}
                                                        name={encode.code}
                                                        isActiveCard={isActiveCard === encode.id}
                                                        code={encode.start_from}
                                                        onRemove={() => {
                                                            const removeDetail = {
                                                                title: t("app.common.remove_generic_title"),
                                                                position: position,
                                                                message: t("app.common.remove_generic_description"),
                                                                endpoint: getPathParam([PRODUCT_ENCCODE, encodingData.id, PRODUCT_ENCODE_ATTRIBUTE, encode.id]),
                                                            };
                                                            onRemove(removeDetail)
                                                        }}
                                                        onEdit={(r) => {
                                                            onEdit(encode);
                                                        }}
                                                        onClone={() => {
                                                            onClone(childId, encode);
                                                        }}
                                                    />
                                                )
                                            }
                                        </div>
                                    ))}
                                </>
                            )
                        }

                    </LoaderWrapper>




                </div>


            </div>
        </div>
    )

}

const CombinationEdit = ({ isOpen, setIsOpen, editDetail, encodeData, onUpdateData }) => {

    const { t } = useTranslation();

    // update
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
        register
    } = useForm({ mode: "onBlur" });


    useEffect(() => {
        if (watch('code') && editDetail.type !== 'incrementer') {
            setValue('number', watch('code').length);
        }
        // eslint-disable-next-line
    }, [watch('code')]);


    useEffect(() => {
        if (editDetail !== null) {
            if (editDetail.type === 'incrementer') {
                setValue('start_from', editDetail.start_from);
                setValue('code', editDetail.code);
            } else {
                setValue('code', editDetail.code);
                setValue('number', editDetail.number);
                setValue('description', editDetail.description);
                setValue('children_title', editDetail.children_title);
            }
        }

    }, [editDetail, setValue]);

    const onSubmit = async (data) => {
        if (editDetail) {
            setLoading(true);
            data['id'] = editDetail.id;
            putElement(getPathParam([PRODUCT_ENCCODE, encodeData.id, PRODUCT_ENCODE_ATTRIBUTE, editDetail.id]), data)
                .then(response => {
                    toast.success(t("app.tasks.update_success"));
                    onUpdateData(response);
                    setIsOpen(false);
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                    setException(e.message);
                });
        }
    };

    return (
        <FormPopup
            title={t("app.catalog.edit_configuration")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-2">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
                        {
                            editDetail.type === 'general' && (
                                <>
                                    <InputText
                                        id={"code"}
                                        type={"text"}
                                        errors={errors.code}
                                        input={{
                                            ...register("code", { required: true }),
                                        }}
                                        label={t("app.catalog.code")}
                                        isRequired={true}
                                    />

                                    <Controller
                                        name="number"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.catalog.generetor_charaters")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={true}
                                                errors={error}
                                            />
                                        )}
                                    />


                                    <InputText
                                        id={"description"}
                                        type={"text"}
                                        errors={errors.description}
                                        input={{
                                            ...register("description", { required: false }),
                                        }}
                                        label={t("app.catalog.description")}
                                        isRequired={false}
                                    />

                                    <InputText
                                        id={"children_title"}
                                        type={"text"}
                                        errors={errors.children_title}
                                        input={{
                                            ...register("children_title", { required: false }),
                                        }}
                                        label={t("app.catalog.children_title")}
                                        isRequired={false}
                                    />
                                </>
                            )
                        }



                        {
                            editDetail.type === 'incrementer' && (
                                <>

                                    <Controller
                                        name="code"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.company_preference.sequential_number")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={false}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="start_from"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.company_preference.next_sequence")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={''}
                                                isDisabled={false}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </>
                            )
                        }

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.common.confirm")}
                    />
                </div>
            </form>


        </FormPopup>
    )

}

const ColomnHeader = ({title, onClick}) => {

    return(
        <div className="flex items-center justify-between  sticky top-0 bg-solitude-400">
            <div className=" my-2 ">
                <div className='text-blue-1000 font-semibold whitespace-pre-line' style={{ fontSize: '14px' }}>
                    {title}
                </div>
            </div>
            <div className="cursor-pointer" onClick={onClick}>
                <PlusIcon className="w-4 h-4 ml-4 text-gray-900 group-hover:text-gray-500" />
            </div>
        </div>
    )

}

const CombinationCard = ({ type, code, name, onRemove, onEdit, onClick, isActiveCard, onClone, description = '' }) => {

    const { t } = useTranslation();
    return (
        <div onClick={(e) => {
            e.preventDefault();
            onClick()

        }} className={classNames(
            isActiveCard ? 'border bg-green-100 border-green-900/[0.2]  shadow-sm' : 'bg-white',
            'mt-3 mx-auto w-full border rounded-md group cursor-pointer'
        )}>
            <dl className={'flex items-center justify-between'}>
                <div className={"flex flex-col p-3 cursor-pointer"}>
                    <div className={"flex items-center text-left"}>
                        <div>
                            <div className="text-sm text-gray-900">
                                <span>
                                    {name}
                                </span>
                            </div>
                            <div className="text-xs text-gray-500" style={{fontSize: '0.70rem'}}>
                                {type === 'incrementer' ? t("app.company_preference.sequential_number") : t("app.catalog.name")}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"text-right mr-3 "}>
                    <div className="block text-right mr-3 flex items-center group-hover:hidden">
                        <div>
                            <div className="text-sm text-gray-900">
                                <span>
                                    {code}
                                </span>
                            </div>
                            <div className="text-xs text-gray-500" style={{fontSize: '0.70rem'}}>
                                {type === 'incrementer' ? t("app.company_preference.next_sequence") : t("app.catalog.code")}
                            </div>
                        </div>
                        <ChevronRightIcon className=" ml-2 h-5 w-5"/>
                    </div>

                    <div
                        className="hidden group-hover:block group-hover:cursor-pointer group-hover:flex group-hover:items-center">
                        <button
                            type="button"
                            className="text-gray-900 hover:text-gray-700  ml-2"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClone()
                            }}
                        >
                            <DuplicateIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                        <button
                            type="button"
                            className="text-gray-900 hover:text-gray-700  ml-2"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEdit()
                            }}
                        >
                            <PencilIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                        <button
                            type="button"
                            className="text-red-700 hover:text-red-900  ml-2"
                            onClick={(e) => {
                                e.stopPropagation();
                                onRemove()
                            }}
                        >
                            <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
            </dl>
            <p className={'text-sm text-gray-900  -mt-2 mb-2 px-3'}>{description}</p>

        </div>
    )

}

export default ManageEncoding;
