import React, {useEffect, useState} from "react";
import { Switch } from '@headlessui/react'
import {useTranslation} from "react-i18next";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const InputVariantToggle = ({label, description, errors, warning, defaultChecked = false, isDisabled = false, onChange, showDetails = true}) => {

    const { t } = useTranslation();
    const [enabled, setEnabled] = useState(defaultChecked)


    useEffect(() => {
        setEnabled(defaultChecked);
    }, [defaultChecked]);


    return (
        <Switch.Group as="div" className={showDetails ?"flex items-center justify-between" : "" }>
            {
                showDetails && (
                    <span className="flex-grow flex flex-col mr-10">
                        <Switch.Label as="span" className="text-sm font-medium text-white" passive>
                            {label}
                        </Switch.Label>
                    </span>
                )
            }

            <Switch
                checked={enabled}
                onChange={(r) => {
                    if(!isDisabled){
                        setEnabled(r);
                        onChange(r);
                    }
                }}
                className={classNames(
                    enabled ? 'bg-green-400' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-1000'
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label.toLowerCase()})}</p>)}
        </Switch.Group>
    )
};
export default InputVariantToggle;
