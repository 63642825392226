import React, {Fragment, useEffect, useState} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { MapIcon} from "@heroicons/react/outline";
import Loader from "../loader/loader";
import {getElement} from "../../../api/config";
import {getPathParam} from "../../../utils/converter";
import { PLACES } from '../../../api/endpoints';
import ShippingIcon from '../../../resources/icons/ShippingIcon';


export default function InputPortCountrySelect({ 
    label = '',
    isDisabled = false,
    selectedItem = null,
    onSelect,
}) {


    const [query, setQuery] = useState('');

    const [second, setSecond] = useState(0);
    const [startCounter, setStartCounter] = useState(false);

    const [list, setList] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [selected, setSelected] = useState(selectedItem ? selectedItem : {})


    useEffect(() => {
        const interval = setInterval(() => {
            if (startCounter) {
                setSecond(second + 1);
                if (((query.length >= 1 || query.length === 0) &&  second === 1)) {
                    setStartCounter(false);
                    setSecond(0);
                    getElement(
                        getPathParam([PLACES]),
                        null,
                        { query: query, selected_id: selectedItem?.id ?? null }
                    ).then((response) => {
                            setList([...response.ports, ...response.countries])
                            setLoading(false);
                        }).catch((e) => {
                            setLoading(false);
                        });

                }
            }
        }, 200);

        return () => {
            clearInterval(interval);
        };
    });

    

    useEffect(() => {

        if (query.length === 0) {
            setList([]);
        }

        // eslint-disable-next-line
    }, [query]);

    const highlightQuery = (name, query) => {
        if (!query) return name; // Se la query è vuota, restituisci il nome originale
        const regex = new RegExp(`(${query})`, 'gi');
        return name.replace(regex, '<mark>$1</mark>'); // Evidenzia la query con il tag <mark>
    };

    const filteredList =
        query === ''
            ? list
            : list.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
                .sort((a, b) => {
                    const lowerQuery = query.toLowerCase();
                    const aName = a.name.toLowerCase();
                    const bName = b.name.toLowerCase();

                    // Calcola la posizione della query all'interno dei nomi
                    const aIndex = aName.indexOf(lowerQuery);
                    const bIndex = bName.indexOf(lowerQuery);

                    // Se la query non esiste nella stringa, considera meno rilevante (indice alto)
                    if (aIndex === -1) return 1;
                    if (bIndex === -1) return -1;

                    // Ordinamento per posizione (più vicina all'inizio è più rilevante)
                    if (aIndex !== bIndex) return aIndex - bIndex;

                    // Ordinamento per lunghezza della stringa (stringhe più corte sono più rilevanti)
                    return aName.length - bName.length;
                });



    return (
        <div className="w-full">
            <Combobox disabled={isDisabled} value={selected} onChange={(r) => {
                onSelect(r);
                setSelected(r);
            }}>

                <div className="relative ">

                    <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm">
                        
                        <label className="py-2 pl-3 block text-sm font-medium -mb-3 text-gray-700">
                            {label}
                        </label>
                        
                        <Combobox.Input
                            className="w-full border-none py-0.5 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
                            placeholder={'Cerca per paese o porto'}
                            autoComplete='off'
                            displayValue={(item) => list.length > 0 ? item?.name ?? '' : selectedItem?.name ?? ''}
                            onChange={(event) => {
                                setQuery(event.target.value)
                                setSecond(0);
                                setStartCounter(true);
                            }}
                        />
                    </div>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-[20rem] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

                            {
                                isLoading ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                            {filteredList.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            
                                                    filteredList.map((item) => (

                                                <Combobox.Option
                                                    key={item.id}
                                                    className={({ selected, active }) =>
                                                        `relative cursor-pointer select-none py-2 px-4 ${
                                                            (active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-10 w-10 rounded-md bg-blue-1000'}>
                                                                        <span className="font-medium leading-none text-white">
                                                                            {
                                                                                item.type === 'country' ? (
                                                                                    <MapIcon className={'h-5 w-5 text-blue-100'} />
                                                                                ) : (
                                                                                    <ShippingIcon className={'h-5 w-5 text-blue-100'} />
                                                                                )
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="ml-3">
                                                                            <p className="text-sm font-medium text-blue-1000 group-hover:text-gray-900 uppercase" dangerouslySetInnerHTML={{
                                                                                __html: `<b>${highlightQuery(item?.name ?? '', query)}</b>`,
                                                                            }}></p>
                                                                    {
                                                                        item.code && (
                                                                            <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700">
                                                                                {
                                                                                    item.type === 'country' ? (
                                                                                        <><b>ISO:</b> {item.code} </>
                                                                                    ) : (
                                                                                        <><b>UN/Locode:</b> {item.code} </>
                                                                                    )
                                                                                }
                                                                            </p>
                                                                        )
                                                                    }
                                                                    {
                                                                        item.type === 'port' && (
                                                                            <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700">{item?.country?.name + ', ' ?? ''} {item?.country?.code ?? ''}</p>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </>
                                )
                            }


                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
