import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {getPermission, permissionGroup, permissionSpecific} from "../../../constants/permissions";
import { useTranslation } from "react-i18next";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import Nodata from "../../../resources/images/no-data.gif";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Dialog } from "@headlessui/react";
import InputToggle from "../../../components/common/form/input-toggle";
import { getPathParam, serializeArray } from "../../../utils/converter";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { getElementBody } from "../../../api/config";
import { COMPANY, FILTERS, GENERAL_TRAFFIC_LINE } from "../../endpoints";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import Initials from "../../../components/common/avatar/initials";
import { getFormattedDate } from "../../../utils/timeUtils";
import NumberFormat from "react-number-format";
import { RatesSearchForm } from "./rates";
import InputVariantToggle from "../../../components/common/form/input-variant-toggle";
import InputRangeFilter from "../../../components/common/form/input-range-filter";
import { classNames } from "../../../utils/Utils";
import { WhiteButton } from "../../../components/layout/page-header";
import ManageForwarder from "../booking/booking-component/manage-forwarder";
import Pagination from "../../../components/common/table/pagination";

const RateSearch = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { company } = useSelector((s) => s.userCompany);
  const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();

  //Manage Forwarder
  const [isOpenManageForwarder, setOpenManageForwarder] = useState(false);


  // API STATES
  const getDefaultFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    // Estrai i parametri specifici
    const expectedDate = searchParams.get('departure_date');
    const fromPlace = searchParams.get('from_place');
    const toPlace = searchParams.get('to_place');
    const from = searchParams.get('from');
    const to = searchParams.get('to');
    const nearestPort = searchParams.get('nearest_port');
    const fromName = searchParams.get('from_name');
    const toName = searchParams.get('to_name');
    const type = searchParams.get('type');

    let params = {
      type: type,
      from: from,
      to: to,
      from_place: fromPlace,
      to_place: toPlace,
      departure_date: expectedDate,
      nearest_port: nearestPort,
      from_name: fromName,
      to_name: toName,

    };
    return params
  }

  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
      "page"
    );

    if (pageNumber) {
      return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }


  const [isFetchLoading, setFetchLoading] = useState(true);
  const [loadingRates, setLoadingRates] = useState(true);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [filters, setFilters] = useState(false);
  const [rateList, setRateList] = useState([]);
  const [rows, setRows] = useState(0);
  const [limit] = useState(30);
  const [offset, setOffset] = useState(checkOffset());


  // FILTER STATES
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState([{ filter_name: "order_by", value: "cheapest" }])
  const [defaultFilters, setDefaultFilters] = useState(getDefaultFilters());


  

  // Main Filters
  const onSubmit = async (data) => {
    let params = {
      type: data.type,
      from: data.from.code,
      to: data.to.code,
      from_place: data.from.type,
      to_place: data.to.type,
      departure_date: data.departure_date,
      nearest_port: getDefaultFilters()?.nearest_port ?? false,
      from_name: data.from.name,
      to_name: data.to.name,
    };
    setActiveFilters([{ filter_name: "order_by", value: "cheapest" }]);
    await updateData(params);
  };


  const updateData = async (params) => {
    navigate(location.pathname + serializeArray([
      { type: params.type },
      { departure_date: params.departure_date },
      { from_place: params.from_place },
      { to_place: params.to_place },
      { from: params.from },
      { from_name: params.from_name },
      { to: params.to },
      { to_name: params.to_name },
      { nearest_port: params?.nearest_port ?? false }
    ]));
    setOffset(0);
    setDefaultFilters(params);
    onSearchRates(null, params, activeFilters, true)
    onSearchFilters(null, params)
  }; 



  // Sidebar Filter
  useEffect(() => {
    if (filters && activeFilters.length > 1){
      setOffset(0);
      onSearchRates(null, defaultFilters, activeFilters, true)
    }
    // eslint-disable-next-line
  }, [activeFilters]);


  
  // Sidebar Filter
  useEffect(() => {
    if(offset > 0){
      onSearchRates(null, defaultFilters, activeFilters)
    }
    // eslint-disable-next-line
  }, [offset]);


  const onSearchRates = async (signal, params, data, isResetOffset = false) => {
    setLoadingRates(true);
    getElementBody(getPathParam([COMPANY, loggedCompany.id, GENERAL_TRAFFIC_LINE]), signal, { ...params, ...{ limit: limit, offset: isResetOffset ? 0 : offset }}, data)
      .then(response => {
        setRateList(response.lines);
        setRows(response.total_rows);
        setLoadingRates(false);
      }).catch(e => {
        setLoadingRates(false);
      });
  };

  const onSearchFilters = async (signal, params) => {
    
    setLoadingFilters(true)
    getElementBody(getPathParam([COMPANY, loggedCompany.id, GENERAL_TRAFFIC_LINE, FILTERS]), signal, params)
      .then(response => {
        setFilters(response);
        setLoadingFilters(false)
      }).catch(e => {
        setLoadingFilters(false)
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchShippings() {
      if (loggedCompany) {
        setFetchLoading(true);
        try {
          onSearchRates(signal, getDefaultFilters(), activeFilters);
          onSearchFilters(signal, getDefaultFilters());

          setFilters(filters);
        } catch (e) {
          setFilters([]);
          if (signal.aborted) {
            setFetchLoading(true);
          }
        } finally {
          if (signal.aborted) {
            setFetchLoading(true);
          } else {
            setFetchLoading(false);
          }
        }
      }
    }

    fetchShippings();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);




  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FORWARDER_RATE, permissionSpecific.SEATCH_RATES)]} renderBaseOnFail={true}>
   
        {/* Mobile filter dialog */}
        <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hidden">
          

          <div className="fixed inset-0 z-40 flex">
            <Dialog.Panel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="relative -mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Close menu</span>
                  <XIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                {/* {filters.map((section) => (
                  <Disclosure key={section.name} as="div" className="border-t border-gray-200 pb-4 pt-4">
                    <fieldset>
                      <legend className="w-full px-2">
                        <Disclosure.Button className="group flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                          <span className="text-sm font-medium text-gray-900">{section.name}</span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              aria-hidden="true"
                              className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                            />
                          </span>
                        </Disclosure.Button>
                      </legend>
                      <Disclosure.Panel className="px-4 pb-2 pt-4">
                        <div className="space-y-6">
                          {section.options.map((option, optionIdx) => (
                            <div key={option.value} className="flex items-center">
                              <input
                                defaultValue={option.value}
                                id={`${section.id}-${optionIdx}-mobile`}
                                name={`${section.id}[]`}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`${section.id}-${optionIdx}-mobile`}
                                className="ml-3 text-sm text-gray-500"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </fieldset>
                  </Disclosure>
                ))} */}
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>

      <main className="mx-auto max-w-2xl lg:max-w-full ">

        <RatesSearchForm onSubmit={onSubmit} isMainSearch={false} formClass="px-4 pt-3" />

          <LoaderWrapper isLoading={isFetchLoading}>
            <div className="pb-24 px-4 lg:px-8  pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-5">
              <aside>
                <h2 className="sr-only">Filters</h2>

                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(true)}
                  className="inline-flex items-center lg:hidden"
                >
                  <span className="text-sm font-medium text-gray-700">Filters</span>
                  <PlusIcon aria-hidden="true" className="ml-1 h-5 w-5 shrink-0 text-gray-400" />
                </button>

                <div className="hidden lg:block">

               
                {
                  ((getDefaultFilters()?.from_place === 'port' ?? false) && ((getDefaultFilters()?.to_place === 'port' ?? false)) ) && (
                    <div className={"w-full mb-5"}>
                      <WhiteButton
                        extraClassName={'w-full'}
                        onClick={() => {
                          setOpenManageForwarder(true);
                        }}
                        icon={
                          <PlusIcon
                            className="w-4 h-4"
                            aria-hidden="true"
                          />
                        }
                        text={t('app.booking.add_forwarder')}
                      />
                    </div>
                  )
                }           

                

                <NearestPort
                  isActive={getDefaultFilters()?.nearest_port ?? false}
                  onChangePort={async (e) => {
                    let params = getDefaultFilters();
                    params.nearest_port = e;

                    await updateData(params);
                  }}
                />

                <LoaderWrapper isLoading={loadingFilters}>

                  <div className="space-y-5 divide-y divide-gray-200">

                    <RangeFilters
                      filters={filters?.filters?.transit_time ?? []}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      title={'Tempo di transito'}
                      label={'transit_time'}
                      symbol={t("app.booking.day")}
                      symbols={t("app.booking.days")}
                    />


                    <RangeFilters
                      filters={filters?.filters?.total_cost ?? []}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      title={'Costo Totale'}
                      label={'total_cost'}
                      symbol={(company?.currency?.currency_symbol ?? '')}
                      symbols={(company?.currency?.currency_symbol ?? '')}
                    />


                    {/* <RangeFilters
                      filters={filters?.filters?.extra_stops_cost ?? []}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      title={'Costo Extra Prese'}
                      label={'extra_stops_cost'}
                      symbol={(company?.currency?.currency_symbol ?? '')}
                      symbols={(company?.currency?.currency_symbol ?? '')}
                    /> */}


                    <RangeFilters
                      filters={filters?.filters?.free_time ?? []}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      title={'Free Time'}
                      label={'free_time'}
                      symbol={t("app.booking.day")}
                      symbols={t("app.booking.days")}
                    />




                    <ToogleFilters 
                      filters={filters?.filters?.shipping_lines ?? []}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      title={'Compagnie Marittime'}
                      label={'shipping_lines'}
                    />


                    <ToogleFilters
                      filters={filters?.filters?.forwarders ?? []}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      title={"Spedizioniere"}
                      label={'forwarders'}
                    />

                    
                    {
                      (filters?.filters?.departure_port ?? []).length > 1 && (
                        <ToogleFilters
                          filters={filters?.filters?.departure_port ?? []}
                          activeFilters={activeFilters}
                          setActiveFilters={setActiveFilters}
                          hasExtra={true}
                          title={'Porto di partenza'}
                          label={'departure_port'}
                        />
                      )
                    }


                    {
                      (filters?.filters?.arrival_port ?? []).length > 1 && (
                        <ToogleFilters
                          filters={filters?.filters?.arrival_port ?? []}
                          activeFilters={activeFilters}
                          setActiveFilters={setActiveFilters}
                          hasExtra={true}
                          title={"Porto d'arrivo"}
                          label={'arrival_port'}
                        />
                      )
                    }

                   



                    <ToogleFilters
                      filters={filters?.filters?.terms_type ?? []}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      title={'Incoterms'}
                      label={'terms_type'}
                    />


                  </div>

                  </LoaderWrapper>

                  
                </div>
              </aside>




            <section   aria-labelledby="product-heading" className={(loadingFilters || loadingRates) ? "mt-6 lg:col-span-2 lg:mt-0 xl:col-span-4 animate-pulse opacity-30": "mt-6 lg:col-span-2 lg:mt-0 xl:col-span-4"}>

              <div>
               
                  {
                  (rateList.length > 0) && (
                    <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                      <PriceOrdering
                        isActive={activeFilters.find(r => r.filter_name === 'order_by' && r.value === 'cheapest' )}
                        title={'Il piu economico'}
                        type={'cheapest'}
                        price={filters?.special?.cheapest?.price ?? 0}
                        transitTime={filters?.special?.cheapest?.transit_time ?? 0}
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}
                        
                      />

                      <PriceOrdering
                        isActive={activeFilters.find(r => r.filter_name === 'order_by' && r.value === 'best')}
                        title={'Il migliore'}
                        type={'best'}
                        price={filters?.special?.best?.price ?? 0}
                        transitTime={filters?.special?.best?.transit_time ?? 0}
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}

                      />


                      <PriceOrdering
                        isActive={activeFilters.find(r => r.filter_name === 'order_by' && r.value === 'fastest')}
                        title={'Il piu veloce'}
                        type={'fastest'}
                        price={filters?.special?.fastest?.price ?? 0}
                        transitTime={filters?.special?.fastest?.transit_time ?? 0}
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}
                      />
                    </dl>
                  )
                  }
               
               



              </div>

              <div className="grid grid-cols-1 gap-y-1 mb-2">

                {
                (rateList.length === 0 && !loadingRates) && (
                    <>
                      <div className="w-full mx-auto px-4 py-3 text-center bg-white  rounded-md">
                        <div className="text-center p-10">
                          <img src={Nodata} className="-mt-8 mx-auto" alt="not-found" />
                          <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                            {t("app.common.not_found")}
                          </h1>
                        </div>
                      </div>
                    </>
                  )
                }



              {rateList.map((rate) => (

                  <>
                  
                  <div className="mx-auto max-w-2xl mt-2 rounded-lg border border-gray-200 bg-white  lg:mx-0 lg:flex items-center lg:max-w-none">
                    
                    
                    <div className="p-4  w-full">
                      <div className="flex items-center justify-between">
                        <span className="flex items-center">
                          <Initials
                            iconClass=' h-12 w-12'
                            color={rate?.forwarder?.color ?? ''}
                            name={rate?.forwarder?.name ?? ''}
                            isVerified={false}
                          />
                          <div className="text-left">
                            <p className="truncate text-xs text-gray-500">{rate?.reference ?? ''}</p>
                            <p className="flex-wrap  whitespace-normal text-sm font-medium text-gray-900">{rate?.forwarder?.name ?? ''}</p>
                            <p className="flex-wrap  whitespace-normal text-sm font-medium text-gray-700">Spedizioniere: <b>{rate?.shipping_line?.name ?? ''}</b></p>
                          </div>
                        </span>

                        <div class="flex items-center space-x-2 text-gray-800 mr-5">
                          <div class="flex flex-col items-center">
                            <span class="text-2xl font-semibold">{rate.company_traffic_line.departure_port.code}</span>
                            <span class="text-sm font-medium text-gray-500">{getFormattedDate(rate.departure_date)}</span>
                          </div>

                          <div class="flex items-center space-x-1">
                            <div class="h-px w-8 bg-gray-400"></div>
                            <div class="text-sm font-bold text-gray-900">{rate.transit_time} {rate.transit_time > 1 ? t("app.plan.days") : t("app.plan.day")}</div>
                            <div class="h-px w-8 bg-gray-400"></div>
                          </div>

                          <div class="flex flex-col items-center">
                            <span class="text-2xl font-semibold">{rate.company_traffic_line.arrival_port.code}</span>
                            <span class="text-sm font-medium text-gray-500">{getFormattedDate(rate.arrival_date)}</span>
                          </div>
                        </div>
                      </div>
                    
                    </div>

                    <div className="-mt-2 p-2 lg:mt-0   lg:w-full lg:max-w-[250px] lg:shrink-0">
                      <div className="rounded-2xl bg-gray-50 py-3 px-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center ">
                        <p className="flex items-baseline justify-center max-w-2xl ">
                          <span className="text-3xl font-semibold tracking-tight text-gray-900">
                            <NumberFormat
                              value={rate?.total_cost ?? 0}
                              displayType={"text"}
                              decimalScale={2}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={" " + (company?.currency?.currency_symbol ?? '')}
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  </>



                  
                  
                  
                ))}
              </div>

              <Pagination
                isLoading={loadingRates}
                total={rows}
                limit={limit}
                offset={offset}
                onChange={setOffset}
              />

              </section>
         
            </div>

          <ManageForwarder
            isOpen={isOpenManageForwarder}
            setIsOpen={setOpenManageForwarder}
            companyTrafficLine={filters?.company_traffic_line ?? false}
            bookingDetail={{ id: 1, shipping_type: {code: 'sea'}}}
            isBooking={false}
            negotiationList={[]}
            onRefresh={async (r) => {
              let params = getDefaultFilters();
              await updateData(params);

            }}
          />

          </LoaderWrapper>

        
      </main>
      
    </BasePermissionWrapper>
  );
};


const NearestPort = ({ isActive, onChangePort }) => {

  const {
    control,
    setValue
  } = useForm();

  // Sidebar Filter
  useEffect(() => {
    if (isActive) {
      setValue('nearestPort', isActive)
    }
    // eslint-disable-next-line
  }, [isActive, setValue]);


  


  return (
    <div className={''}>
      <fieldset>
        <div className="space-y-3 " >
          <div className="text-left">
            <Controller
              name={"nearestPort"}
              rules={{ required: false }}
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <InputVariantToggle
                  
                  label={(<b className="text-gray-900">Vuoi includere anche i porti vicini?</b>)}
                  onChange={(e) => {
                    onChange(e)
                    onChangePort(e)
                  }}
                  defaultChecked={value}
                  errors={error}
                />
              )}
            />
          </div>
        </div>
      </fieldset>
    </div>
  )
}



const PriceOrdering = ({ isActive = false, title, price, transitTime, type, activeFilters, setActiveFilters }) => {

  const { company } = useSelector((s) => s.userCompany);
  const { t } = useTranslation();


  return (
    <div className={classNames(isActive && 'bg-blue-1000', "px-4 py-4 cursor-pointer")} onClick={() => {

      if (activeFilters.find(r => r.filter_name === 'order_by')) {
        const updatedFilters = activeFilters.map(filter => {
          if (filter.filter_name === 'order_by') {
            return {
              ...filter,
              value: type,
            };
          }
          return filter;
        });
        setActiveFilters(updatedFilters);
      } else {
        const list = [...activeFilters];
        list.push({ filter_name: 'order_by', value: type });
        setActiveFilters(list);
      }


    }}>
      <dt className={classNames(isActive ? 'text-base font-semibold text-white' : "text-base font-semibold text-gray-900")}>{title}</dt>
      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
        <div className="flex items-baseline ">
          <span className={classNames(isActive ? "text-xl font-bold tracking-tight text-white" : "text-xl font-bold tracking-tight text-blue-1000")}>
            <NumberFormat
              value={price}
              displayType={"text"}
              decimalScale={2}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={" " + (company?.currency?.currency_symbol ?? '')}
            />
          </span>
        </div>

      </dd>
      <dd className="-mt-1 flex items-baseline justify-between md:block lg:flex">
        <div className="flex items-baseline ">
          <span className={classNames(isActive ? "text-sm font-semibold tracking-tight text-white" : "text-sm font-semibold tracking-tight text-gray-900")}>
            <NumberFormat
              value={transitTime}
              displayType={"text"}
              decimalScale={2}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={" " + (transitTime > 1 ? t("app.plan.days") : t("app.plan.day"))}
            />
          </span>
        </div>
      </dd>
    </div>
  )
}


const RangeFilters = ({ filters, activeFilters, setActiveFilters, title, label, symbols, symbol}) => {

  const {
    watch,
    register,
    setValue
  } = useForm();
  
  const [maxFilters, setMaxFilters] = useState(filters.max);

  useEffect(() => {
    setValue(label, filters.max);
    // eslint-disable-next-line
  }, [setValue, filters]);

  useEffect(() => {

    if (watch(label) !== maxFilters) {
      setMaxFilters(watch(label));
   
    }
    // eslint-disable-next-line
  }, [watch(label)]);

  if (!filters || filters.length === 0) {
    return (<></>);
  }

  return (
    <div className={'pt-6'}>
      <fieldset>
        <legend className="block text-sm font-bold text-gray-900">{title}</legend>
        <div className=" pt-6 ">
          <div className="flex items-center  ">
            <p class=" text-gray-800 text-sm font-semibold text-gray-900 mr-2">
              <NumberFormat
                value={filters.min ?? 0}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
                suffix={" " + (filters.min ? (filters.min === 1 ? symbol : symbols) : "")}
              />
            </p>{" - "}
            <p class=" ml-2 text-gray-800 text-sm font-semibold text-gray-900 ">
              <NumberFormat
                value={maxFilters ?? 0}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
                suffix={" " + (maxFilters ? (maxFilters === 1 ? symbol : symbols) : "")}
              />

            </p>
          </div>
          <InputRangeFilter
            className={'form-range-filter rounded-lg overflow-hidden cursor-pointer appearance-none bg-gray-200 h-3 w-full'}
            min={filters.min}
            max={filters.max}
            input={{ ...register(label, { required: true }) }}
            handleRangeChange={(val) => {
              if (activeFilters.find(r => r.filter_name === label)) {
                const updatedFilters = activeFilters.map(filter => {
                  if (filter.filter_name === label) {
                    return {
                      ...filter,
                      min_value: filters.min,
                      max_value: val,
                    };
                  }
                  return filter;
                });
                setActiveFilters(updatedFilters);
              } else {
                const list = [...activeFilters];
                list.push({ filter_name: label, min_value: filters.min, max_value: val });
                setActiveFilters(list);
              }
            }}
          />

        </div>
      </fieldset>
    </div>
  )
}



const ToogleFilters = ({ title, label, hasExtra = false,  filters, activeFilters, setActiveFilters }) => {

  const {
    control,
  } = useForm();


  if (filters.length === 0) {
    return (<></>)
  }


  return (
    <div className={'pt-10'}>
      <fieldset>
        <legend className="block text-sm font-bold text-gray-900">{title}</legend>
        <div className="space-y-3 pt-6">

          {
            filters.map((filter) => (
              <Controller
                name={filter.id}
                rules={{ required: false }}
                control={control}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <InputToggle
                    label={!hasExtra ? filter.name : (filter.name + " (" + filter.code + ")") }
                    onChange={(e) => {
                      if (!e && activeFilters.find(r => r.filter_name === label && r.value === filter.id)) {
                        setActiveFilters(activeFilters.filter(r => r.value !== filter.id))
                      } else {
                        const list = [...activeFilters];
                        list.push({ filter_name: label, value: filter.id });
                        setActiveFilters(list);
                      }
                      onChange(e)
                    }}
                    defaultChecked={value}
                    errors={error}
                  />
                )}
              />
            ))
          }
        </div>
      </fieldset>
    </div>
  )
}





export default RateSearch;
